.month-report-container {
  flex: 1;
  .month-report {
    flex: 1;
    font-size: 16px;
    color: var(--color-white);
    border-radius: 7px;
    padding: 5px 15px;
    margin: 5px;
    cursor: pointer;
  }
}