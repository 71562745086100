.chart-container {
  overflow: auto;

  &__chart {
    width: 725px;
    max-width: 900px;
    height: 55vh;
  }
  &__card {
    border: 1px solid var(--color-grey-light-2);
    -webkit-box-shadow: 0 0 5px var(--color-grey-dark-6);
    box-shadow: 0 0 5px var(--color-grey-dark-6);
    border-radius: 1rem;
    margin: 0 1rem;
    min-width: 25rem;
    max-width: 30rem;
    overflow: auto;
    &-emps {
      .emp {
        border-bottom: 1px solid var(--color-grey-light-2);
        &:last-child {
          border-bottom: none;
        }
        p {
          font-size: var(--font-size-1);
          color: var(--color-grey-dark-2);
        }
        .price {
          font-size: var(--default-font-size);
          color: var(--color-main);
          font-weight: bold;
          letter-spacing: 0.1rem;
        }
      }
    }
    hr {
      margin: 1rem -1.5rem;
    }
    &-shifts {
      .table {
        display: table;
        color: var(--color-grey-dark-2);
        overflow: auto;
        text-align: center;
        .total {
          border-top: 1px solid var(--color-grey-light-2);
        }
      }
    }
  }
}
