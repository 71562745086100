.main-container {
  display: flex;
  // TODO messam uncomment this code
  // @media (min-width: 1280px) {
  //   & {
  //     max-width: 1280px;
  //     margin: auto;
  //   }
  // }
  // for test
  min-height: 100vh;
  flex-direction: column;
  & > div:last-child {
    flex: 1;
  }
}
