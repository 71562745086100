.attachments-upload-container {
  position: relative;
  .upload-item {
    position: relative;
    cursor: pointer;
    //   width: 15rem;
    margin: auto;
    border: 1px solid var(--color-main);
    border-radius: 4px;
    padding: 0.5rem;
    font-size: var(--font-size-2);
    outline: none;
    height: 3.7rem;
    img {
      height: 1rem;
      width: 1rem;
    }
    .upload {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0 0.5rem;
    }
    p {
      font-size: 1.15rem;
      // overflow: hidden;
      // max-width: 10rem;
      white-space: nowrap;
      // text-overflow: ellipsis;
      // min-width: 9rem;
    }
    .progress {
      width: 100%;
      direction: ltr;
      margin: 0.5rem 0;
      .progress-bar {
        background-color: var(--color-main);
      }
    }
  }
  .uploader-container {
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;
    max-height: 15rem;
    overflow: auto;
    display: none;
    .upload-items {
      border-bottom: 1px solid gray;
      .upload-item {
        border: none;
        height: 4.7rem;
        &-header {
          p {
            width: 12.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            img.upload.warning {
              height: 10px;
              float: right;
            }
          }
        }
      }
    }
  }
  .open {
    border: 1px solid var(--color-main);
    border-radius: 5px;
    display: block;
  }
}
