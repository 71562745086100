.modal-content-wrapper {
  margin-top: 1em;
  display: flex;
  height: 90%;
  .close-img {
    cursor: pointer;
    position: absolute;
    right: 2em;
    top: 1em;
  }

  .component-wrapper {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
  }
  .add-operation{
    display: flex;
    align-items: flex-end;
    div{
      background-color:var(--color-main) ;
      color: white;
      font-size: 12px;
      border-radius: 10px;
      padding: 5px 25px;
      font-family: 'Segoe UI';
      cursor: pointer;
    }
  }
}
