.section-header {
    .d-flex {
        height: 40px;
    }
    .title {
        font-size: var(--font-size-2);
        font-weight: 700;
        // height: 50px;
    }
    .add {
        display: flex;
        .btn {
            font-size: var(--font-size-3);
            border-radius: 8px;
            padding: 0px 8px;
            margin-left: 4px;
            height: 27px;
        }
    }
    .print {
        display: flex;
        .btn {
            font-size: var(--font-size-3);
            border-radius: 8px;
            padding: 0px 12px;
            margin-left: 4px;
            height: 27px;
            img {
                width: 18px;
            }
        }
    }
    input.form-control {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #0090ff;
        border-radius: 7px;
        opacity: 1;
        height: 30px;
    }
}
