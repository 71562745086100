.unit-details-container {
  flex: 1;
  width: 100%;
  &__main {
    flex: 1;
    & > div {
      flex: 1;
      // background-color: var(--color-main-light);
      // border-radius: 1rem;
      // margin: 0 1rem;
    }
    img {
      height: 1.5rem;
    }
    h6 {
      margin: 0 1rem;
    }
    h5 {
      margin: 0;
    }
    select,
    input,
    textarea {
      width: 94%;
    }
    // textarea {
    // }
    .shared {
      text-align: right;
      font-size: 1rem;
      font-family: 'GE SS Two';
      direction: rtl;
    }
    .right {
      height: 7rem;
      // padding: 1rem;
    }
    .address {
      height: 5.5rem;
    }
    .error {
      text-align: start;
      font-size: var(--font-size-4);
      padding: 0.5rem 0;
      color: var(--color-danger);
      height: 2.4rem;
    }

    .details > div,
    .pres > div:not(.notice),
    .employees-container > div {
      background-color: var(--color-main-light);
      border-radius: 1rem;
      // margin: 0 1rem;
    }
    .offers-container > .offers-body {
      background-color: var(--color-main-light);
      border-radius: 1rem;
      min-height: 20px;
      // margin: 0 1rem;
    }
    .offers-container > .offers-body > .offers {
      // width: 100%;
      & > div {
        flex: 5;
        position: relative;
        .passwordInput {
          position: relative;
          .arrow {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            right: 10%;
            top: 30%;
            pointer-events: none;
          }
        }

        .close-iconn {
          cursor: pointer;
          position: absolute;
          right: 10%;
        }
      }

      .btn-end {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
    .employees-container {
      .description {
        background: none;
        .descriptionText {
          border-radius: 1rem;
          background-color: var(--color-main-light);
          color: var(--color-main);
        }
      }
    }
    .pres {
      .notice {
        background-color: var(--color-white);
        position: relative;
        h5 {
          span {
            font-size: 0.9rem;
            margin: 0 1rem;
            color: var(--color-grey-dark-2);
          }
        }

        img {
          cursor: pointer;
          margin: 0 1rem;
          &:focus {
            outline: none;
          }
        }
        p {
          // position: absolute;
          // right: 3.6rem;
          margin-bottom: -1.5rem;
          margin-left: -0.4rem;
          background-color: var(--color-white);
          padding: 0.6rem;
          box-shadow: 0 0 5px var(--color-grey-dark-6);
          border-radius: 0.5rem;
          font-size: 1rem;
          color: var(--color-grey-dark-6);
        }
      }
    }
    .pres-online > div {
      flex: 1;
    }
    .tel,
    .dr {
      & > div {
        flex: 1;
      }
    }
    .name-gov,
    .employees {
      // width: 100%;
      & > div {
        flex: 1;
        position: relative;
        .passwordInput {
          position: relative;
          .arrow {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            right: 10%;
            top: 30%;
            pointer-events: none;
          }
        }

        .close-iconn {
          cursor: pointer;
          position: absolute;
          right: 10%;
        }
      }
    }
    .experience {
      // width: 100%;
      & > div {
        flex: 1;
        position: relative;
      }
    }
  }
  .add {
    color: var(--color-main);
    text-decoration: underline;
    background-color: var(--color-white);
    text-align: end;
    span {
      cursor: pointer;
    }
  }
  .map-container {
    .btn-container {
      width: 100%;
      margin: 0;
      button {
        img {
          width: 2rem;
          height: 2rem;
        }
        span {
          margin: 0 0.5rem;
        }
      }
    }
    .address {
      // margin: 0 2rem;
      input {
        width: 30rem;
      }
    }
  }
  .no-map {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    height: 22.1rem;
    cursor: pointer;
    img {
      width: 2rem;
      height: 2rem;
    }
    p {
      color: var(--color-main);
    }
  }

  .addClinc-btn-container {
    display: flex;
    flex-direction: column;
    .btn-container {
      align-self: flex-end;
      width: 20rem;
      margin: 2rem 0;
    }
  }
}

[dir='rtl'] {
  .unit-details-container {
    .arrow {
      right: unset;
      left: 3%;
    }
    .close-icon {
      left: 10% !important;
      right: unset !important;
    }
  }
}
