.result-container {
  font-size: 12px;
  strong {
    text-transform: uppercase;
  }
  .ele {
    font-family: 'Segoe UI';
  }

  .ele:hover {
    font-size: 14px;
    cursor: pointer;
  }
}
