.vitalSigns-container {
  flex: 1;
  width: 100%;
  font-size: 1.5rem;
  text-align: start;
  &__main {
    &-data {
      flex: 1;
     // width: 15%;
      border-radius: 1rem;
      background-color: var(--color-main-light);
      border: 1px solid var(--color-main);
      // min-width: 17%;
      min-width: 18rem;
      img {
        height: 9rem;
      }
      .check {
        p {
          flex: 1;
          text-align: left;
          // padding: 0 0.5rem;
          margin: 0;
        }
      }
    }
  }
  .btn-container {
    align-self: flex-end;
    width: 20rem;
    margin: 2rem 0;
  }
  // .back {
  //   text-align: end;
  // }
}
@media only screen and (min-width: 1020px) {
  [class*="vitalSigns-container__main-data"] {
    max-width: 17%;
  }
}
