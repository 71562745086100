.clinic-exam-view {
  // .board-container {
  .col-top {
    min-height: 140px;
    height: auto;

    .component {
      min-height: 120px;
      height: auto;

      .tags {
        height: 60px;
        overflow-y: auto;
      }
    }
  }

  .col-top.expand {
    min-height: 30vh;
    height: auto;

    .component {
      min-height: 30vh;
      height: auto;

      .tags {
        height: 200px;
        overflow-y: auto;
      }
    }
  }

  .col-content.collapsed {
    max-height: calc(100vh - 433px);
    overflow-y: hidden;
  }

  .col-bottom.collapsed {
    max-height: 60vh;
    overflow: hidden;
  }

  .col-bottom {
    .column {
      padding-right: 15px;
      padding-left: 15px;
      min-width: 208px;
    }

    .column-fixed {
      width: 270px;
      flex: 1;
    }

    .column-default {
      width: calc((100vw - 523px) / 2);
      flex: 1;
    }

    .column-expanded {
      width: calc((100vw - 350px) / 2);
    }

    .column-collapsed {
      width: calc((100vw - 823px) / 2);
    }
  }

  .col-content {
    .tags {
      max-height: 30vh;
      overflow-y: auto;
    }

    .item {
      // display: inline-block;
      padding: 2px 8px;
      margin: 2px;
      border-radius: 8px;
      border: none;
    }

    .item.current {
      background-color: var(--color-main-light);
    }

    .highlight {
      color: #d45800;
      font-weight: 700;
      background-color: transparent;
      padding: 0px;
    }

    .sub {
      margin-left: 24px;
    }

    .result-container {
      max-height: 30vh;
      overflow-y: auto;
    }

    .item-text {
      line-height: 30px;
    }

    .nested {
      margin-left: 20px;
    }
  }

  strong {
    text-transform: uppercase;
  }
}
