.workingDays-container__days--day {
  border-radius: 1rem;
  background-color: var(--color-main-light);
  min-width: 32rem;
  // font-size: 1.3rem;
  padding: 1rem 2rem;
  &-shifts {
    text-align: start;
    input {
      width: 6rem !important;
      height: 3.5rem;
      text-align: center;
      border: 1px solid var(--color-main);
      border-radius: 3px;
      font-size: 1.4rem;
      color: var(--color-grey-dark-1);
      direction: ltr;
      &::placeholder {
        color: var(--color-grey-dark-1);
      }
    }
    &-shift {
      .time {
        background-color: var(--color-grey-dark-1);
        padding: 0.3rem;
        border-radius: 1rem;
        margin: 0 0.5rem;
        display: flex;
        & > div {
          position: relative;
          &:last-child {
            margin-left: 0.2rem;
          }
        }
        select {
          width: 5rem;
          height: 3rem;
          padding: 0;
          /* text-align-last: center; */
          border-radius: 0.6rem;
          padding-left: 0.5rem;
          color: var(--color-main);
        }
        img {
          position: absolute;
          height: 1.2rem;
          right: 5px;
          top: 10px;
          pointer-events: none;
        }
      }
      .to {
        margin: 0 2rem;
      }
      span {
        align-self: flex-start;
        // margin: 0 2rem;
        color: var(--color-danger);
        height: 2rem;
      }
    }
    .timing {
      @media only screen and (max-width: 505px) { 
        button {
          margin-bottom: 2em;
        }
      }
      button {
        font-family: inherit;
      }
      .remove {
        color: var(--color-danger);
        // margin: 0 2rem;
      }
    }
  }
}

