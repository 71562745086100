.clinic-name-container {
  flex: 1;

  .form-container {
    flex: 1 1 40%;

    .lock-icon {
      img {
        height: 2rem;
        width: 2rem;
      }
    }

    hr.promo-separator {
      border-top: 2px dashed #0090ff;
      margin-right: -3rem;
      margin-left: -3rem;
      margin-top: 0;
      opacity: 0.5;
    }

    h6 {
      margin: 0 1rem;
    }

    &__form {
      flex: 1;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      width: 30rem;

      form {
        width: 100%;

        .select-container {
          .arrow {
            top: 20%;
          }
        }
      }
    }
  }
}