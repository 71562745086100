.terms-modal-container {
  .close-img {
    cursor: pointer;
  }
  .terms {
    overflow: auto;
    height: 49rem;
    // border-top: 1px solid var(--color-grey-light-4);
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    .underline {
      text-decoration: underline;
    }
  }
}
