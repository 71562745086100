.select-service-wrapper {
  border-right: var(--color-gery-light-6) solid 1px;
  margin-left: 1em;
  * {
    font-family: 'Segoe UI';
  }
  h1 {
    font-size: 1.7em;
    cursor: pointer;
    color: var(--color-grey-dark-7);
  }

  .active-header {
    color: var(--color-main);
  }

  .service-cats-wrapper {
    margin-top: 1em;

    .cat-wrapper {
      h1 {
        color: var(--color-grey-dark-7);
        cursor: pointer;
      }

      ul li {
        color: var(--color-grey-dark-7);
        align-items: center;
        font-size: 1.7em;
        cursor: pointer;        list-style: none;
      }

      .service {
        margin: 1em 2em;

        .selected-service {
          list-style: disc;

          align-items: center;

          color: var(--color-main);
        }
      }
    }
  }
}
