.forget-password-page-container {
  flex: 1 1 30%;
  &-msg {
    text-align: start;
  }
  &-form {
    & > div {
      flex: 1;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      max-width: 35rem;
      margin: auto;
      .mobile-icon {
        img {
          height: 1.7rem;
          margin: 0px 4px;
        }
      }
      form {
        width: 100%;
      }
    }
    .new-register {
      color: var(--color-grey-dark-1);
      p {
        font-size: unset;
      }
      a {
        text-decoration: underline;
        &:hover {
          color: var(--color-main);
        }
      }
    }
  }
}
