.prescription-component {
    .table.drug {
        background: var(--color-main-light);
        width: 100%;
        tr {
            td {
                border-top: 2px solid #ffffff;
                border-bottom: 2px solid #ffffff;
                font-size: var(--font-size-4);
                font-weight: 700;
            }
        }
    }
}
