$secondary-title-font-size: 14px;
.dental-page {
  display: flex;
  overflow: hidden;

  .col-top {
    height: 140px;
    padding-top: 20px;
    margin-bottom: 8px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .left-side {
    .col-top {
      display: inline-flex;
    }

    width: 273px;
    display: block;
    height: calc(100vh - 70px);
    position: relative;

    a.menu-btn {
      display: block;
      margin-top: 20px;
      width: 36px;
      margin-left: 8px;
      text-align: center;
      margin-right: 8px;

      img {
        width: 32px;
      }
    }

    .menu-col {
      margin-top: 20px;
    }

    .patient-info {
      background: var(--color-main-light) 0% 0% no-repeat padding-box;
      height: 120px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 8px;
      width: 220px;

      .btns {
        text-align: center;
        margin-top: 20px;

        .btn {
          margin: 4px;
          min-width: 80px;
        }
      }
      .price {
        font-size: var(--font-size-1);
        color: var(--color-red-1);
        font-weight: bold;
      }
      .money-sign {
        width: 2rem;
        height: 2rem;
        color: var(--color-white);
        border-radius: 4rem;
        font-size: var(--font-size-1);
        background-color: var(--color-red-1);
        display: flex;
        justify-content: center;
      }
    }

    .list {
      background-color: var(--color-main-light);
      height: calc(100vh - 70px);
      margin-left: 52px;

      .list-group {
        .list-group-item-action:focus {
          outline: none;
        }
        .refer-button {
          width: 90%;
          height: 3rem;
          border-radius: 1rem;
          background-color: var(--color-main);
          color: var(--color-white);
          font-size: var(--font-size-3);
        }

        .financial-container {
          margin: 2rem 0;
          .price {
            font-size: var(--font-size-1);
            color: var(--color-main);
            font-weight: bold;
          }
          .financial-text {
            font-size: var(--font-size-1);
          }
          .money-sign {
            width: 2rem;
            height: 2rem;
            color: var(--color-white);
            border-radius: 4rem;
            font-size: var(--font-size-1);
            background-color: var(--color-main);
            display: flex;
            justify-content: center;
          }
        }

        .list-group-item {
          border: none;
          background: none;
          font-size: $secondary-title-font-size;
          font-weight: 500;
          min-height: 52px;
          cursor: pointer;

          div.item-title {
            font-size: $secondary-title-font-size;
            margin-left: 8px;
            line-height: 32px;
            font-weight: 500;

            small {
              display: block;
              line-height: 0;
            }
          }

          img {
            height: 32px;
          }
        }

        .list-group-item.active {
          color: var(--color-main);
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 15px #0090ff61;
          border-radius: 3px;
        }

        .list-group-item.history {
          padding: 0;

          .history-date-list {
            // margin-left: 30px;
            max-height: 202px;
            overflow-y: auto;
            min-height: 52px;

            .list-group-item.head {
              border: none;
              background: none;
              font-size: $secondary-title-font-size;
              font-weight: 500;
              // min-height: 52px;
              cursor: pointer;
              padding-left: 12px;
              padding-top: 12px;
            }

            .list-group-item {
              min-height: 30px;
              border: none;
              background: none;
              font-size: 14px;
              font-weight: 500;
              cursor: pointer;
              padding-left: 50px;
              padding-top: 0px;
            }
          }
        }

        .list-group-item.history.active {
          .history-date-list {
            padding-top: 40px;

            .list-group-item.head {
              position: absolute;
              top: 0;
              background: white;
              left: 0;
              right: 0;
              z-index: 1;
            }

            .list-group-item {
              padding-top: 4px;
              padding-bottom: 4px;
              color: var(--color-grey-dark-3);
            }

            .list-group-item.selected {
              background-color: var(--color-main-light);
              color: var(--color-main);
            }
          }
        }
      }
    }

    // .component {
    //   //min-height: calc(50vh - 120px);
    // }
  }

  .page-content {
    // width: calc(100vw - 300px);
    overflow-y: auto;
    width: calc(100vw - 274px);
    height: calc(100vh - 70px);
    overflow: auto;
    .no-patient {
      width: 100%;
      height: 100%;
    }
  }

  #overlay {
    position: fixed;
    // min-width: 300px;
    height: calc(100vh - 70px);
    top: 70px;
    left: 0;
    bottom: 0;
    z-index: 3;
    cursor: pointer;
    width: 273px;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08),
      0 1px 3px 1px rgba(66, 66, 66, 0.16);
  }

  .reservation-list {
    background-color: white;

    .reservation-list-header {
      background-color: var(--color-main);
      text-align: right;
      position: absolute;
      left: 0;
      right: 0;
      height: 40px;

      .btn.close {
        color: white;
        font-size: 22px;
        padding: 4px 12px;
        opacity: 1;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      span {
        color: white;
        font-weight: bold;
        font-size: var(--font-size-1);
        margin: auto;
        margin-left: 8px; // float: left;
      }
    }

    .reservation-list-body {
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 0px;
      height: calc(100vh - 70px);
      padding-bottom: 4px;

      .booking-item-container {
        border-radius: 0;
        // width: 95%;
        overflow: hidden;
        margin: 0;
        margin-bottom: 2px;
        direction: rtl;
      }
      .no-patient {
        margin-top: 40vh;
        p {
          font-size: var(--default-font-size);
        }
        .msg {
          font-size: var(--font-size-5);
        }
      }
    }
  }
}

.close-icon {
  transform: rotate(-45deg);

  .d {
    fill: #0090ff;
  }
}
