.complete-profile-check {
  flex: 1 1 50%;
  .register-profile-check-container {
    flex: 1;
    .nat-num-profile > div {
      flex: 1;
      padding: 0 1rem;
    }
    background-color: var(--color-main-light);
    border-radius: 1rem;
    padding: 1.7rem 1rem;
    padding-bottom: 0;
    p {
      text-align: start;
    }
    input[type='file'] {
      display: none;
    }
    .national-id {
      .nat-num {
        text-align: start;
        .error {
          height: 1.8rem;
          span {
            color: var(--color-danger);
          }
        }
      }
    }

    .percent {
      font-size: var(--font-size-3);
      padding: 2px;
      background: var(--color-white);
      border-radius: 5px;
      color: var(--color-main);
    }
  }
  // .national-id-img {
  //   .upload {
  //     border-radius: 1rem;
  //     span {
  //       max-width: 10rem;
  //       text-align: center;
  //     }
  //   }
  // }

  .create-account {
    .btn-container {
      width: 60%;
      margin: auto;
    }
  }
}
