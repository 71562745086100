.row.prescription-modal {
    // padding: 20px;
    min-height: 350px;
    .prescription-modal-header {
        .title {
            font-size: var(--font-size-2);
            font-weight: 700;
        }
        .add {
            display: flex;
            .btn {
                font-size: var(--font-size-3);
                border-radius: 8px;
                padding: 0px 8px;
                height: 27px;
            }
        }
        .print {
            display: flex;
            height: 30px;
            .btn {
                font-size: var(--font-size-3);
                border-radius: 8px;
                padding: 0px 12px;
                height: 27px;
                img {
                    width: 18px;
                }
            }
        }
    }
    .prescription-modal-body {
        .form-row {
            padding: 4px;
            margin-bottom: 4px;
            background-color: var(--color-main-light);
            .d-flex {
                width: 100%;
            }
            .toget-newline{
                display: flex;
                flex-direction:column;
                
            }
            .form-group {
                margin: 2px;
                .controller-container {
                    display: inline-flex;
                    margin: 4px;
                    .control-label {
                        font-size: 12px;
                        font-weight: 700;
                        font-size: 12px;
                    }
                    .control-label.start {
                        margin-right: 2px;
                    }
                    .control-label.end {
                        margin-left: 2px;
                    }
                }
                .form-control {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #0090ff;
                    border-radius: 7px;
                    opacity: 1;
                    height: 30px;
                    font-size: 12px;
                    font-weight: 700;
                    width: 100%;
                    min-width: 50px;
                    text-transform: capitalize;
                }
                .period-group {
                    width: 30%;
                }
                .duration-group {
                    display: flex;
                    .form-control {
                        width: 50px;
                        text-align: center;
                        text-transform: capitalize;
                    }
                    .form-control.start {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .form-control.end {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
               
                .title {
                    label {
                        font-weight: 700;
                        text-transform: capitalize;
                    }
                }
                .bills {
                    width: 150px;
                   display: inline-block;
                    .form-control {
                        width: 20px;
                        text-align: center;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .form-control.start {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;

                    }
                    .form-control.end {
                        width: 90px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    .unit {
                        text-transform: capitalize;
                        display: inline-block;
                    }
                }
                .btn.remove { 
                    font-size: 20px;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                }
            }
        }
        .selected-drugs {
            height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .search-result {
            height: 50vh;
            overflow-y: auto;
            button.btn-drug {
                margin: 4px;
            }
            .list-group {
                background-color: var(--color-main-light);
                margin-bottom: 8px;
                .list-group-item.head {
                    padding: 4px;
                    font-weight: 700;
                    font-size: 120%;
                    cursor: default;
                }
                .list-group-item.exist {
                    text-decoration: underline;
                    font-weight: 400;
                    font-size: 110%;
                    cursor: not-allowed;
                }
                .list-group-item {
                    padding: 4px;
                    padding-left: 8px;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}
