.lab-bookings {
  flex: 1 1 60%;
  border-radius: 1rem;

  & > div {
    height: 100%;
    background-color: var(--color-main-light);
  }
  .table.table-responsive {
    display: inline-table;
  }
  .table {
    th,
    td {
      border: none;
      font-weight: normal;
      vertical-align: unset;
      padding: 0.5rem 0;
    }
    tbody {
      tr:nth-of-type(even) {
        background-color: var(--color-main-light);
      }
      tr:nth-of-type(odd) {
        background-color: var(--color-white);
      }
      tr.active {
        color: var(--color-main);
        border-left: 11px solid var(--color-main);
      }
      tr {
        border-left: 11px solid transparent;
      }
      th,
      td {
        text-align: center;
        font-family: 'GE SS Two';
      }
      .mobile-num {
        font-size: 1.3rem;
        padding: 0 1rem;
      }
      .upload-status {
        width: 40px;
        img {
          height: 15px;
        }
      }
    }
    thead {
      tr {
        background-color: var(--color-main-light) !important;
        th {
          max-width: 19rem;
          padding: 1.59rem 1rem;
          div {
            padding: 0 1rem;
            h6 {
              text-align: start;
              font-size: 1.1rem;
            }
            .input-container {
              padding: 0;
              background-color: var(--color-main-light);
              border-bottom: 0.2rem solid var(--color-main);
              img {
                height: 1.2rem;
              }
              input {
                border-radius: unset;
                height: 3rem;
                border: none;
                flex: 1;
                margin: 0 0.5rem;
                background-color: var(--color-main-light);
                width: 100%;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .no-patient-td {
      background-color: var(--color-main-light);
      vertical-align: middle;
      .no-patient {
        // margin-top: 40vh;
        p {
          font-size: var(--default-font-size);
        }
        .msg {
          font-size: var(--font-size-5);
        }
      }
    }
  }
}
