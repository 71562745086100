.finances-container {
  // flex: 1;
  background-color: #f2f9ff;
  margin: 5px;
  padding: 2px;
  box-shadow: 1px 3px 5px #00000029;
  border-radius: 5px;
  font-size: 12px;
  width: 200px;
  min-width: 170px;
}
