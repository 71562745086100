.diagnoses-component {
    .item {
        // display: inline-block;
        border: 1px solid #dedede;
        padding: 2px 8px;
        margin: 2px;
    }
    .item.current {
        // background-color: green;
        background-color: var(--color-main-light);
    }

    .item-text {
        line-height: 30px;
    }
}
