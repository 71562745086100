.finances-page {
  .finances-body {
    width: 75%;
    .report-botton {
      flex: 1;
      flex-wrap: wrap;
    }
  }
}
.daily-reports-screen-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  .daily-reports-total-income {
    .income-and-expenses-cards {
      margin-top: 10px;
      display: flex;
    }
    .doctors-income-cards {
      display: flex;
      justify-content: flex-start;
      max-width: 70%;
      flex-wrap: wrap;
    }
  }
  .total-income-card {
    width: 250px;
    background-color: var(--color-main);
    height: 50px;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    p {
      font-size: 13px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .total-income-value {
      font-size: 25px;
    }
  }
  .total-income-details-card {
    width: 250px;
    background-color: #f2f9ff;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div {
      display: flex;
      justify-content: space-between;
      p {
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 12px;
      }
    }
  }
  .total-expenses-card {
    width: 250px;
    background-color: #f31d73;
    margin-left: 20px;
    height: 50px;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    p {
      font-size: 13px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .total-income-value {
      font-size: 25px;
    }
  }
  .graph-section {
    width: 50%;
    p {
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
.daily-reports-graph-section {
  width: 50%;
  p {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 10px;
  }
}
.daily-reports-graph-section {
  width: 50%;
  p {
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .reports-screen-header,
  .daily-reports-screen-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .reports-screen-header,
  .daily-reports-screen-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .reports-screen-header,
  .daily-reports-screen-container {
    width: 1400px;
  }
}
.reports-screen-header {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  .daily-monthly-cards {
    border: 1px solid #0090ff;
    width: 200px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    color: #0090ff;
    height: 35px;
    cursor: pointer;
    .chosen-daily-monthly-card {
      background-color: #0090ff;
      width: 100px;
      height: 35px;
      color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      p {
        font-size: 13px;
      }
    }
    .not-chosen-daily-monthly-card {
      width: 100px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      p {
        font-size: 13px;
      }
    }
  }
}
