.profile-form {
  flex: 1 1 40%;
  //max-width: 40rem;
  min-width: 34rem;
  // padding: 2rem 3rem;
  .profile-form-container {
    // flex: 1;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    // padding: 2rem 1rem;
    .name-group {
      margin-bottom: 0.5rem;
      img {
        height: 1.8rem;
      }
      h6 {
        margin: 0 0.5rem;
      }
    }
    .input-container,
    .select-container {
      // width: 90%;
      input {
        padding: 7px;
        text-align: end;
        &::placeholder {
          text-align: start;
        }
      }
      select {
        padding: 7px;
        top: 20%;
      }
    }

    .btn-container {
      width: 100%;
      margin: auto;
      padding: 1.5rem;
      margin-top: -2.5rem;
    }
    .password-container {
      & > div {
        flex: 1;
      }
    }
    form {
      width: 100%;
    }
  }
}
