.complain-wrapper {
  .section {
    font-size: var(--default-font-size);

    textarea {
      width: 90%;
      min-height: 15rem;
    }
  }
  button {
    margin: 2rem;
    width: 17rem;
    height: 4rem;
    border-radius: 1rem;
    background-color: var(--color-main);
    color: var(--color-white);
    font-size: var(--font-size-1);
    font-weight: lighter;
    border-style: none;
  }
}
