.testsTypes-container {
  flex: 1;
  width: 100%;
  &__main {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
  }
  @media (min-width: 576px) {
    &__main {
      grid-template-columns: repeat(2, minmax(48%, 1fr));
    }
  }
  // &__button {
  .btn-container {
    align-self: flex-end;
    width: 20rem;
    margin: 2rem 0;
  }
  // }
}
