.addEmployeesAndexpenses-container {
  flex: 0.9;
  margin: 0 auto;
  .exp-container {
    flex: 0.7;
    > div {
      background-color: var(--color-main-light);
      border-radius: 1rem;
    }
    // margin: 0 1rem;
  }
  .add {
    color: var(--color-main);
    text-decoration: underline;
    background-color: var(--color-white);
    text-align: end;
    span {
      cursor: pointer;
    }
  }
  .pres-online {
    width: 100%;
    padding: 0 1rem;
  }
  .addEmployees-container {
    flex: 1;
    width: 100%;
    &__main {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(1, minmax(100%, 1fr));
      .form {
        background-color: var(--color-main-light);
        border-radius: 1rem;
        & > div {
          flex: 1;
        }
        .inputs {
          text-align: start;
          input,
          select {
            width: 90%;
          }
        }
        .role {
          position: relative;
          .close-icon {
            cursor: pointer;
            position: absolute;
            left: 10%;
          }
        }
      }
      img {
        height: 2rem;
        width: 2rem;
      }
      h6 {
        margin: 0 1rem;
      }
      .error {
        text-align: start;
        font-size: var(--font-size-4);
        padding: 0.5rem 0;
        color: var(--color-danger);
        height: 2.4rem;
      }
    }
    // @media (min-width: 576px) {
    //   &__main {
    //     grid-template-columns: repeat(2, minmax(48%, 1fr));
    //   }
    // }

    .add-emp {
      color: var(--color-main);
      // font-size: 1.3rem;
      font-family: inherit;
      align-self: flex-end;
    }
    .btn-container {
      align-self: flex-end;
      width: 20rem;
      margin: 2rem 0;
    }
  }
}
[dir='ltr'] {
  .addEmployees-container {
    .close-icon {
      right: 10% !important;
      left: unset !important;
      img {
        transform: rotate(45deg);
      }
    }

    @media only screen and (max-width: 575px) {
      //Set to call 'mobile'
      .close-icon {
        right: 1% !important;
        left: unset !important;
        bottom: 13rem;
      }
    }
  }
}
