.passwordModal-container {
  .header {
    background-color: var(--color-main-light);
    img {
      height: 2rem;
      cursor: pointer;
    }
    h3 {
      color: var(--color-main);
    }
  }
  .form-container {
    .password {
      img {
        height: 1.5rem;
      }
      h6 {
        margin: 0 1rem;
      }
      .input-container {
        input {
          background-color: var(--color-main-light);
        }
      }
    }
  }
}
