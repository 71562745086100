.change-password-page-container {
  flex: 1 1 30%;
  &-msg {
    text-align: start;
  }
  .image-name {
    align-items: center;
    img {
      width: 5rem;
      height: 5rem;
      border-radius: 2.5rem;
    }
  }

  .change-password-page-form {
    & > div {
      flex: 1;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      max-width: 35rem;
    }
    .lock-icon {
      img {
        height: 1.7rem;
        margin: 0px 4px;
      }
    }
    form {
      width: 100%;
      .forget {
        a {
          color: var(--color-grey-dark-1);
        }
      }
    }
  }
}
