.details-container {
  background-color: var(--color-main-light);
  border-radius: 1rem;
  flex: 1 1 40%;
  h6 {
    margin: 0 1rem;
    font-family: 'GE SS Two';
  }
  &__header {
    padding: 2rem;
    .result-container {
      // padding: 0 2rem;
      h6 {
        margin: 0 0.5rem;
        font-size: var(--font-size-2);
        color: var(--color-main);
        font-weight: bold;
        font-family: 'Segoe UI';
      }
      .reports {
        margin: 0 2rem;
      }
    }
    img {
      height: 2rem;
    }
    .btn-container {
      margin: 0;
    }
  }
  &__data {
    & > div:nth-child(odd) {
      background-color: var(--color-white);
    }
    .image-container {
      .reports {
        margin: 0 2rem;
      }
    }
    img {
      height: 1rem;
      cursor: pointer;
    }
    p {
      font-size: 0.8rem;
      margin: 0 1rem;
      & span:last-of-type {
        color: var(--color-main);
      }
    }
    h6 {
      font-weight: bold;
    }
    .uploading {
      .upload-icon {
        height: 1.5rem;
      }
      p {
        font-size: 1rem;
        margin: 0 1.4rem;
      }
    }
    .profile {
      outline: none;
      cursor: pointer;
      img {
        height: 1.5rem;
      }
    }
  }
}
