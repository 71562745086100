.otp-page-container {
  flex: 1 1 40%;
  & > div {
    max-width: 30rem;
  }
  .name {
    width: 100%;
  }
  .otp-page-form {
    flex: 1;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    width: 30rem;
    .otp-msg {
      .mobile {
        direction: ltr;
        font-size: var(--default-font-size);
      }
    }
    form {
      width: 100%;
    }
  }
}
