.reservation-list {
  .reservation-list-sub-header {
    display: flex;
    margin-top: 46px;
    margin-bottom: 6px;
    padding: 0px 8px;
    direction: ltr;

    button {
      background-color: Transparent;
      border: none;
      outline: none;
      display: inline-flex;
    }

    span {
      padding-right: 5px;
      font-size: 14px;
      color: var(--color-main);
      display: inline-flex;
      line-height: 20px;
    }
  }

  .booking-item-container {
    border-radius: 0;
    width: 273px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 0;

    .date-time {
      padding: 4px 8px;
      color: var(--color-white);
      display: flex;
      direction: ltr;

      .numbers {
        padding-left: 8px;

        p {
          font-weight: bold;
          font-size: var(--font-size-2);
        }
      }

      button {
        background-color: Transparent;
        border: none;
        outline: none;
        display: inline-flex;
      }

      span {
        padding-right: 5px;
        font-size: 14px;
        color: #ffffff;
        display: inline-flex;
        font-weight: bold;
      }



      img {
        margin: auto 0.5rem;
        cursor: pointer;
      }
    }
  }
}