.profile-page {
  flex: 1;
  &-header {
    display: flex;
    justify-content: space-between;
    // margin: 0 2rem;
    &-title {
      font-size: var(--font-size-2);
      font-weight: bold;
    }
    &-actionButton {
      display: flex;

      .btn-container {
        align-self: flex-end;
        width: 10rem;
        margin: 2rem 1rem;
        svg {
          height: 1.6rem;
          margin: auto;
        }
      }
    }
  }
  .profile-page__main {
    flex: 1;
    // padding: 1rem 2rem;
    & > div {
      margin: 0 1rem;
    }
  }
  .clinicImage-container {
    flex: 1 1 50%;
    .progress-container {
      width: 81%;
      margin: auto;
      text-align: start;
      .progress {
        width: 60%;
        direction: ltr;
        border: 1px solid var(--color-main);
        border-radius: 5px;
        background-color: var(--color-white);
        .progress-bar {
          background-color: var(--color-main);
        }
      }
      .percent {
        color: var(--color-main);
      }
    }
    img {
      width: 95%;
      max-height: 70vh;
      margin: auto;
    }
  }
}
