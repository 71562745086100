.btn-container {
  margin-top: 0px;
  .button{
    border-radius: 0;
    height: 40px;
    width: 100px;
    font-size: 11px;
  }
  .button-top-ar{
    border-top-left-radius: 10px;
  }
  .button-bottom-ar{
    border-bottom-left-radius: 10px;
  }
  .button-top-en{
    border-top-right-radius: 10px;
  }
  .button-bottom-en{
    border-bottom-right-radius: 10px;

  }

  .button-not-active{
    background: #fff;
    color: #000;
  }
  .span-bottom{
    display: inline-block;
    vertical-align: super;
    width: 20px;
    height: 20px;
    background-color: red;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
  
  }
  .span-bottom-active{
    background-color: white;
    color:red;
  }
}

.box {
  width: 130px;
  height: 80px;
  top: 83px;
  background: #0090ff;
  text-align: center;
  font-weight: 900;
  color: #fff;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.box-ar{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.box-en{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.sb:before{
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.ar:before{
  left: -19px;
  border-left: 10px solid transparent;
  border-right: 10px solid #0090ff;
}
.en:before{
  right: -19px;
  position: absolute;
  border-left: 10px solid #0090ff;
  border-right: 10px solid transparent;
}
.sb1:before {
  top: 48px;
}

.sb2:before {
  top: 10px;
}



.panel-container {
  width: 475px;
  .panel-action {
    .btn-container {
      margin: 0 1rem;
      button {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
  .panel-bookings {
    overflow: auto;
    max-height: 75vh;
    padding-inline-start: 26px;
  }
  .booking-action {
    display: inline-flex;
    justify-content: space-evenly;
    padding-top: 15px;

    span {
      padding-right: 5px;
      font-size: 13px;
      color: var(--color-main);
    }
    .toggle-span {
      display: inline-flex;
    }
    button {
      background-color: Transparent;
      border: none;
      outline: none;
      display: inline-flex;
    }
  }
}

@media only screen and (max-width: 1336px) {
  [class*="panel-container"] {
    width: 385px;
  }
}
