.date-container {
  margin: 0 2rem;
  border: 1px solid var(--color-main);
  border-radius: 4px;
  cursor: pointer;
  .day {
    background-color: var(--color-main);
    color: var(--color-white);
    padding: 0.7rem;
    align-self: stretch;
    width: 6.5rem;
    text-align: center;
  }
  .select {
    position: relative;
    width: 12rem;
    padding: 0.5rem;
    color: var(--color-main);
    border: none;
    img {
      height: 1rem;
      width: 1rem;
    }
  }
}
.react-datepicker-popper[data-placement^='bottom'] {
  left: 1.5rem !important;
  .react-datepicker__triangle {
    left: 50%;
  }
}
