.select-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .arrow {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    left: 3%;
    top: 25%;
    pointer-events: none;
  }
  .error {
    text-align: start;
    font-size: var(--font-size-4);
    padding: 0.5rem 0;
    color: var(--color-danger);
    height: 2.4rem;
  }
}

@media only screen and (max-width: 380px) {
  select[name="specialty"] {
     font-size:112%;
   
 }
}

 @media only screen and (max-width: 360px) {
  select[name="specialty"] {
    font-size:105%;
  }
 }

[dir='ltr'] {
  .select-container {
    .arrow {
      left: unset;
      right: 5%;
    }
  }
}
