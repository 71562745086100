.labs-container {
  flex: 1;

  &__main {
    width: 100rem;
    display: grid;
    align-self: center;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(100px, auto);
    position: relative;
  }
  .details {
    border-radius: 1rem 0 0 1rem;
    background-color: var(--color-grey-light-2);
    height:calc(100vh - 23rem);
    max-height: calc(100vh - 23rem);
    p {
      font-weight: bold;
    }
    .item {
      margin: 1rem;
      font-size: 1.3rem;
    }
    .labName {
      position: absolute;
      left: 1rem;
      bottom: 3rem; 
      width: 31rem;
      height: 3.7rem;
      border: 1px solid var(--color-main);
      border-radius: 1rem;

      background-color: var(--color-white);
      animation: appear 0.3s 1;
      animation-direction: normal;
      img {
        height: 1rem;
        cursor: pointer;
        margin: 1rem;
      }
      input {
        border: none;
        border-radius: 1rem;
        height: 3.5rem;
      }
      Button {
        border-radius: 0.5rem;
        margin: 0.5rem 3rem 0.5rem 0rem;
        height: 3rem;
      }
    }
   @keyframes appear {
      from {
       bottom: 1rem;
      }
      to {
      bottom: 3rem;
      }
   }
   .details-header{
     position: relative;
     align-items: center;
    .button-actions {
      width: 10rem;
      position: absolute;
      right: 2rem;
   }
  }

  }

  .addClinc-btn-container {
    display: flex;
    flex-direction: column;
    .btn-container {
      align-self: flex-end;
      width: 20rem;
      margin: 2rem 0;
    }
  }
}
