.l-booking-data__form {
  flex: 1;
  // max-width: 65rem;
  width: 59rem;
  img {
    // width: 1.5rem;
    height: 1.8rem;
  }
  .l-booking-actions {
    .select-dr {
      position: relative;
      border: 1px solid var(--color-main);
      width: 15rem;
      height: 3rem;
      cursor: pointer;
      border-radius: 0.5rem;
      span {
        font-size: 1rem;
        margin: 0 1rem;
        font-family: 'GE SS Two';
      }
      .arrow {
        height: 1rem;
        margin: 0 1rem;
      }
      ul {
        list-style: none;
        position: absolute;
        top: 3rem;
        background-color: white;
        width: 100%;
        border: 1px solid var(--color-main);
        margin: 0;
        li {
          border-bottom: 1px solid var(--color-grey-dark-1);
          padding: 0.5rem;
          cursor: pointer;
          font-size: 1rem;
          min-height: 2.5rem;
          font-family: 'GE SS Two';
        }
      }
    }
    .btn-container {
      margin: 0 0.5rem;
      button {
        padding: 0.5rem 1rem;
      }
      .warning {
        padding: 0.5rem 2rem;
      }
    }
    .add-scan {
      button {
        img {
          height: 1.5rem;
          width: 1.5rem;
        }
        span {
          margin: 0 0.5rem;
        }
        padding: 0 0.6rem;
        font-size: 1.1rem;
        color: var(--color-white);
        border: none;
        border-radius: 5px;
        background: var(--color-main);
        outline: none;
        cursor: pointer;
        height: 100%;
        margin: 0 0.5rem;
      }
    }
  }
  &-container {
    background-color: var(--color-main-light);
    border-radius: 1rem;
    // padding: 1rem;
    // margin-top: 2rem;
    .table {
      th,
      td {
        border: none;
        font-weight: normal;
        vertical-align: inherit;
        padding: 0.5rem 0;
      }
      tr:nth-of-type(odd) {
        background-color: var(--color-white);
      }
      tr:nth-of-type(even) {
        input {
          background-color: var(--color-main-light);
        }
      }
      thead {
        tr {
          background-color: var(--color-main-light) !important;
          // th {
          //   // padding: 1rem 0;
          // }
        }
        .scan {
          // width: 39%;
          width: 22.1rem;
        }
        .price {
          // width: 13%;
          width: 7rem;
        }
        .date {
          width: 16.5rem;
        }
        .time {
          max-width: 9.4rem;
        }
      }
      .disable-row {
        opacity: 0.5;
      }
      input[type='checkbox'] {
        // flex: 0;
        // width: initial !important;
        margin: 0 0.5rem !important;
        min-width: 2rem;
        max-width: 2rem;
        cursor: pointer;
        height: unset !important;
      }
      .close {
        height: 1rem;
        cursor: pointer;
        margin: 0 0.5rem;
      }
      .main-data {
        .price {
          height: 2.5rem;
          padding: 0;
          text-align: center;
          max-width: 3.5rem;
          margin: 0 1rem;
          border: none;
          color: var(--color-dark);
          &:focus {
            border: 1px solid var(--color-main);
          }
        }
      }
      .custom-scan {
        input {
          // width: 85%;
          height: 3rem;
          margin: 0 1rem;
        }
        .price {
          max-width: 5rem;
          text-align: center;
        }
        select {
          height: 3rem;
          padding: 0;
          margin: 0 0.5rem;
          font-size: 1rem;
          text-align-last: center;
        }
      }
    }
    .total-price {
      .data {
        margin: 0 1rem;
        font-weight: bold;
        span {
          font-size: 0.8rem;
          margin: 0 0.2rem;
          font-weight: normal;
        }
      }
      input {
        max-width: 5rem;
        height: 3rem;
        font-weight: normal !important;
      }
    }
  }
}
