.expiration-alert {
    background-color: white;
    // transform: translateY(0px);

    .expiration-modal-container {
        .alert-icon {
            width: 40px;
            height: 40px;
            background-color: var(--color-danger-light-1);
            color:  var(--color-danger);
            padding: 4px;
            border-radius: 4px;
            margin: 0 8px;
        }

        span {
            color: var(--color-danger);
        }

        svg {

            .b {
                clip-path: none;
            }
        }

    }

    .footer {
        flex-direction: row-reverse;

        .btn-container {
            margin-left: 12px;
            box-shadow: 0px 8px 8px #0090FF29;
            width: 120px;
        }

        .btn-container.danger {
            box-shadow: none;

            button {
                color: var(--color-danger);
                background-color:var(--color-danger-light-1);

            }
        }

    }
}

.expiration-alert:focus {
    outline: none;
}