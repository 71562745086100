.vital-signs-component {
    .form-container {
        max-width: 87%;
    }
    .vital-item {
        .progress-container {
            display: initial;
            align-items: initial;
            padding: 10px 0px 10px 0px;

            .dot {
                height: 12px;
                width: 12px;
                border-color: #f08e38;
                border-style: solid;
                background-color: #fff;
                border-width: 2.5px;
                border-radius: 50%;
                position: absolute;
                top: 20%;
              }

            .progress-parent {
                display: flex;
                height: .75rem;
                overflow: hidden;
                line-height: 0;
                font-size: .75rem;
                background-color: #e9ecef;
                border-radius: .40rem;

                .progress-bar-color {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;
                    color: #fff;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #007bff;
                    transition: width 0.6s ease;
                }
                .progress-child-underweight {
                    width: 46.25%;
                    background-color:#0090FF;
                }
                .progress-child-normal {
                    width: 16.25%;
                    background-color: #31B260;
                }
                .progress-child-overweight{
                    width: 12.5%;
                    background-color: #f08e38;
                }
                .progress-child-obese1 {
                    width: 12.5%;
                    background-color: #ee6f5d;
                }
                .progress-child-obese2 {
                    width: 12.5%;
                    background-color: #FF1654;
                }

            }

            .progress-label {
                display: flex;
                height: 1rem;
                overflow: hidden;
                line-height: 0;
                font-size: .75rem;
                background-color: transparent;
                border-radius: .25rem;

                .progress-bar-label {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;
                    color: #000;
                    text-align: end;
                    white-space: nowrap;
                    background-color: #fff;
                }
                .progress-child-underweight-label {
                    width: 48%;
                }
                .progress-child-normal-label {
                    width: 16.25%;
                }
                .progress-child-obese1-label {
                    width: 12.5%;
                }
            }

        }
    }
}
.Underweight {
	color: #0090FF;
}
.Normal {
	color: #31B260;
}
.Overweight {
	color: #f08e38;
}
.Obese1 {
	color: #ee6f5d;
}
.Obese2 {
	color: #FF1654;
}