.lab-service-modal {
  .modal-inputs {
    display: flex;
    input {
      margin: 1rem;
    }
    .service-name {
      flex: 4 1;
    }
    .price {
      text-align: center;
    }
  }
  .modal-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    .btn-container {
      margin: 1rem;
    }
  }
}
