.selectt {
  position: relative;
  cursor: pointer;
  min-height: 3.5rem;
  min-width: 18rem;
  max-width: 18rem;
  border: 1px solid var(--color-main);
  border-radius: 4px;
  padding: 0.5rem;
  font-size: var(--font-size-2);
  color: var(--color-main);
  outline: none;
  p {
    margin: 0;
    font-family: 'GE SS Two';
  }
  .avatar {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 5px;
    // border-radius: 1.25rem;
  }
  img {
    height: 1rem;
    width: 1rem;
  }
  .dr-list {
    z-index: 10;
    position: absolute;
    max-height: 18rem;
    width: 100%;
    border: 1px solid var(--color-main);
    top: 3.5rem;
    left: 0;
    background-color: white;
    overflow: auto;
    .dr-item {
      border-bottom: 1px solid var(--color-grey-light-4);
      padding: 0.8rem 0.5rem;
    }
  }
  .current-dr {
    width: 16rem;
    p {
      // width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}
