.selected-tag-item.badge {
    font-size: 14px;
    margin: 2px;
    padding: 4px 12px;
    border-radius: 14px;
    background: var(--color-main-light) 0% 0% no-repeat padding-box;
    color: var(--color-main);
    max-width: 100%;
    white-space: pre-wrap;
    display: inline-flex;
    justify-content: space-between;
    line-height: 24px;
}