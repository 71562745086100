.modal-container{
    .close {
        height: 1rem;
        cursor: pointer;
        margin: 0 0.5rem;
    }
    .title{
        font-family: 'Segoe UI';
        font-size: var(--font-size-2);
        font-weight: 700;     
    }
    input{
        border: 1px solid var(--color-grey-dark-1);
        border-radius: 5px;
    }
    .search-input{
      border: none;
    }
    .dr-list{
        z-index: 10;
      border: 1px solid var(--color-main);
      background-color: white;
      .dr-item {
        border-bottom: 1px solid var(--color-grey-light-4);
        padding: 0.8rem 0.5rem;
        cursor: pointer;
      }
    }
    textarea{
        border: 1px solid var(--color-grey-dark-1);
        border-radius: 3px;
        height: 20rem;
    }
    .tests-container{
        height: 23rem;
    }
    .table{
        height: 100%;
        margin-top: 4rem;
        .table-title{
            border: none;
        }
        tbody, thead tr { display: block; }
        tbody {
            height: 190px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        input[type='checkbox'] {
            margin: 0 0.5rem ;
            min-width: 2rem;
            max-width: 2rem;
            cursor: pointer;
            height: unset !important;
          }
    }
    
    
}