.booking-details-container {
  flex: 1;
  .booking-actions {
    .btn-container {
      margin: 0 1rem;
      button {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
  &--data {
    & > div {
      margin: 0 1rem;
    }
    .mobile {
      direction: ltr;
    }
    .patient {
      flex: 1;
      &-header {
        background-color: var(--color-main-light);
        color: var(--color-main);
        border: 2px solid var(--color-main);
        border-radius: 1rem;
        p {
          margin: 0 1rem;
        }
        &-edit {
          border-radius: 0.4rem;
          background-color: var(--color-main);
          padding: 0.5rem;
          cursor: pointer;
        }
      }
    }
    .booking {
      flex: 1;
    }
    .patient-details,
    .booking-details {
      margin: 1rem 0;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      padding: 2rem;
      .booking-item {
        margin: 0 0 1rem 0;
        &.network {
          justify-content: flex-end;
        }
        img {
          height: 1.5rem;
          width: 1.5rem;
        }
        h6 {
          margin: 0 1rem;
          text-align: start;
          .old-price {
            margin-inline-end: 0.2rem;
            color: #707070;
            text-decoration: line-through;
          }
        }
      }
      .payment {
        .btn-container {
          margin-top: 0;
        }
        span {
          color: var(--color-main);
        }
      }
    }
  }
  &--cost {
    margin: 10px 20px;
    .title {
      font-size: 18px;
      font-family: 'GE SS Two';
      text-align: start;
    }
    .costs {
      font-size: 14px;
    }
    div {
      .name {
        color: var(--color-main);
      }
      .due {
        color: var(--color-danger-light-2);
      }
    }
    .bottons {
      min-width: 160px;
      .report {
        background-color: var(--color-main);
        align-self: center;
        padding: 4px;
        border-radius: 5px;
        img {
          height: 26px;
        }
      }
    }
  }
}

@media screen and (min-width: 1365px) {
  .booking-details-container--cost .bottons {
    min-width: 192px;
  }
  .booking-details-container--cost .bottons .report img {
    height: 32px;
  }
}
