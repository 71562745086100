.employees_page {
  flex: 1;
  width: 100%;
  .description {
    .descriptionText {
      border-radius: 1rem;
      background-color: var(--color-main-light);
      color: var(--color-main);
    }
  }
  .addEmployees-container {
    flex: 1;
    width: 100%;
    &__main {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(1, minmax(100%, 1fr));
      .form {
        background-color: var(--color-main-light);
        border-radius: 1rem;
        & > div {
          flex: 1;
        }
        .inputs {
          text-align: start;
          input,
          select {
            width: 90%;
          }
        }
        .role {
          position: relative;
          .close-icon {
            cursor: pointer;
            position: absolute;
            left: 10%;
          }
        }
      }
      img {
        height: 2rem;
        width: 2rem;
      }
      h6 {
        margin: 0 1rem;
      }
      .error {
        text-align: start;
        font-size: var(--font-size-4);
        padding: 0.5rem 0;
        color: var(--color-danger);
        height: 2.4rem;
      }
    }
    // @media (min-width: 576px) {
    //   &__main {
    //     grid-template-columns: repeat(2, minmax(48%, 1fr));
    //   }
    // }

    .add-emp {
      color: var(--color-main);
      // font-size: 1.3rem;
      font-family: inherit;
      align-self: flex-end;
    }
    .btn-container {
      align-self: flex-end;
      width: 20rem;
      margin: 2rem 0;
    }
  }
}
