.add-patient {
  //   flex: 1;
  width: 100%;
  font-size: 1.5rem;
  text-align: start;
  position: relative;
  min-width: 94px;
  .form-container {
    width: 100%;
  }
  form {
    max-width: 50rem;
    margin: auto;
    padding: 1rem;
    .btnn {
      position: absolute;
      left: 4rem;
      .btn-container {
        margin: 0 1rem;
        button {
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}

[dir='ltr'] {
  .add-patient {
    .btnn {
      right: 4rem;
      left: unset;
    }
  }
}
