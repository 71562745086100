.add-booking {
  flex: 0 !important;
  width: 100%;
  font-size: 1.3rem;
  text-align: start;
  & > div {
    flex: 1;
  }
  &-current > div {
    // flex: 1;
    // max-width: 30rem;
    margin: 0 1rem;
  }
  p {
    margin: 0;
  }
  img {
    height: 1.5rem;
  }
  h6 {
    margin: 0 0.5rem;
    font-size: 1.2rem;
  }
  .date {
    font-size: 1.37rem;
  }
  .mobileNum {
    direction: ltr;
  }
  // &-previous {
  //   max-width: 60rem;
  // }
}
