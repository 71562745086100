@mixin on-circle($item-count, $circle-size, $item-size, $invert) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);
    
    $angle: ((360 * $invert) / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg)
          rotateZ($rot + 90deg);
        span {
          transform: rotate(-90deg -$rot);
        }
      }

      $rot: $rot + $angle;
    }
  }
  .teeth-number{
    font-size: 12px;
  }
}

.select-teeth-wrapper {
  border-right: var(--color-gery-light-6) solid 1px;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  //justify-content: center;
  align-items: center;
  * {
    font-family: 'Segoe UI';
  }
  h1 {
    font-size: 1.7em;
    cursor: default;
    color: var(--color-grey-dark-7);
  }

  .active-header {
    color: var(--color-main);
  }

  .age-selection-wrapper {
    cursor: default;
    user-select: none;
    margin-top: 2rem;
    width: 15.8rem;
    height: 4.5rem;
    border: var(--color-main) solid 1px;
    border-radius: 1.2rem;
    display: flex;

    div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--font-size-1);
      color: var(--color-main);
      flex-grow: 1;
      flex-basis: 0;
      cursor: pointer;
    }
    .selected-age {
      background-color: var(--color-main);
      color: var(--color-white);
      border-radius: 1.1rem;
      cursor: default;
    }
  }

  .teeth-wrapper {
    position: relative;
    left: -70px;
    top: 70px;
    .teeth-number{
      font-size: 13px;
      font-weight: bold;
    }
    .set-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      //margin-top: 3em;
      //height: 20rem;
      div {
        //margin: 0.3em;
        cursor: default;
        font-size: var(--font-size-1);
        display: flex;
        flex-direction: column;
        align-items: center;
        user-select: none;
      }
      .normal-tooth {
        .a {
          fill: #f0f0f0;
        }
      }
      .selected-tooth {
        //filter: brightness(0.4);
        .a {
          fill: var(--color-main);
        }
      }
      .normal-tooth-child {
        .a {
          fill: #a7a7a7;
        }
      }
    }
  }
  .select-teeth-input{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h1{
      font-size: 16px;
      margin-right: 10px;
    }
    display: flex;
    select{
      height: 30px;
      max-width: 20%;
    }
  }
}

.teeth-pattern-wrapper {
  @include on-circle(
    $item-count: 32,
    $circle-size: 18em,
    $item-size: 7em,
    $invert: -1,
  );
  margin-top: 8rem;
}
/*.child-teeth-pattern-wrapper2 {
  @include on-circle(
    $item-count: 20,
    $circle-size: 18em,
    $item-size: 7em,
    $invert: -1,
  );
  margin-top: 8rem;
}*/