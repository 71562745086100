.history-view {
  .component {
    height: 250px;
    overflow-y: auto;
  }
  .col-top {
    .tags {
      height: 60px;
      overflow-y: auto;
    }
  }
  .col-content.vitals {
    max-width: 250px;
  }
  .col-content {
    .tags {
      .badge {
        margin: 2px;
        padding: 4px 8px;
        border-radius: 14px;
        background: var(--color-main-light) 0% 0% no-repeat padding-box;
        color: var(--color-main);
        font-size: var(--font-size-4);
        display: inline-block;
        max-width: 100%;
        white-space: pre-wrap;
      }
    }
    .procedures-container {
      overflow: auto;
      //   max-height: 14rem;
      width: 100%;

      .img-container {
        margin-bottom: 1rem;
        width: 10rem;
        cursor: pointer;
        .main-img {
          background-color: var(--color-main-light);
          border-radius: 0.5rem;
          margin: 0 1rem;
          height: 8rem;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
          }
        }
        span {
          width: 7rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .vital-item {
    label.col-form-label {
      padding-right: 8px;
      padding-left: 8px;
      font-size: 12px;
      padding-right: 8px;
      // line-height: 36px;
      text-transform: capitalize;
    }
    .col-sm-1 {
      line-height: 30px;
      text-align: center;
      padding: 0px;
    }
    .col-sm-3,
    .col-sm-7,
    .col-sm-6 {
      padding-right: 8px;
      padding-left: 8px;

      input.form-control {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid var(--color-main);
        border-radius: 7px;
        opacity: 1;
        height: 30px;
      }
    }
  }
  .table.drug {
    background: var(--color-main-light);
    width: 100%;
    tr {
      td {
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        font-size: var(--font-size-4);
        font-weight: 700;
      }
    }
  }
  strong {
    text-transform: uppercase;
  }
}
