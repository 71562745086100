.c-booking-data__form {
  flex: 1;
  min-width: 30rem;
  max-width: 30rem;
  .c-booking-actions {
    .btn-container {
      margin: 0 0.5rem;
      button {
        padding: 0.5rem 1rem;
      }
      .warning {
        padding: 0.5rem 2rem;
      }
    }
  }
  &-container {
    background-color: var(--color-main-light);
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 2rem;
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker__input-container,
    .input-container,
    .select-container {
      input,
      select {
        width: 100%;
        color: var(--color-main);
        text-align: center;
      }
      select {
        text-align-last: center;
      }
    }

    .input-double {
      display: flex;
      justify-content: space-between;
      .input-container {
        max-width: 40%;
      }
      span {
        text-align: center;
        line-height: 39px;
      }
    }
  }
}
