.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(150px);
  transition: all 500ms ease-in-out;
  z-index: 3;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(150px);

}

.modal-btn-group {
  display: flex;

  .btn-container {
    margin: 2rem 8px 0px;

    button {
      font-size: 1.2rem;
      padding: 0.7rem 1rem;
      height: 29px;
      line-height: 12px;
    }
  }
}