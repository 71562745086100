.search {
  outline: none;
  border: 1px solid var(--color-main);
  border-radius: 0.5rem;
  height: 3.5rem;

  .search-input {
    position: relative;
    max-width: 25rem;
    padding: 0 1rem;

    span {
      padding: 0.5rem;
      font-size: var(--font-size-1);
      color: var(--color-grey-dark-2);
    }

    img {
      height: 2rem;
    }

    input {
      flex: 1;
      outline: none;
      border: none;
      background-color: var(--color-white);
      color: var(--color-grey-dark-1);
      padding: 0.1rem 0.3rem 0.5rem;
      font-size: 1.4rem;
      text-align: left;
      font-family: inherit;
      direction: ltr;
      width: 170px;

      &::placeholder {
        color: var(--color-grey-dark-1);
        font-size: 1rem;
        text-align: start !important;
      }

      &:focus,
      &:active {
        color: var(--color-main);
        background-color: var(--color-white);
      }
    }

    .patient-list {
      z-index: 10;
      position: absolute;
      max-height: 15rem;
      width: 100%;
      border: 1px solid var(--color-main);
      top: 3.5rem;
      left: 0;
      background-color: white;
      overflow: scroll;

      .patient-item {
        border-bottom: 1px solid var(--color-grey-light-4);
        padding: 0.8rem 0.5rem;
        cursor: pointer;

        .mobile {
          direction: ltr;
          font-size: 1.3rem;
        }

        .no-result {
          text-align: start;
          padding: 1rem;
        }
      }
    }
  }
}
