.menu-container {
  margin: 0 2rem;
  ul {
    list-style: none;
    margin: 0;
    height: 25px;
    a {
      text-decoration: none;
      margin: 0 1.5rem;
      font-size: var(--default-font-size);
      color: var(--color-grey-dark-2);
      font-weight: bold;
      position: relative;
      cursor: pointer;
    }
    .active {
      color: var(--color-main);
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -7px;
        height: 4px;
        background-color: var(--color-main);
        width: 100%;
      }
    }
  }
}
