.selectUnitType-container {
  flex: 1;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  .title {
    font-size: var(--default-font-size);
    color: var(--color-main);
  }
  &__main {
    &-data {
      flex: 1;
      border-radius: 1rem;
      background-color: var(--color-main-light);
      img {
        height: 7rem;
      }
      .unit-type {
        p {
        }
        // color: var(--color-main);
      }
    }
    &-dataActive {
      flex: 1;
      border-radius: 1rem;
      background-color: var(--color-main);
      img {
        height: 7rem;
      }
      .unit-type {
        p {
        }
        color: var(--color-white);
      }
    }
  }
  .btn-container {
    align-self: flex-end;
    width: 20rem;
    margin: 2rem 0;
  }
}
