.modal-container{
.close-img {
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 1rem;
    height: 1rem;
      }
      .component{
        h1{
            font-size: 16px;
            font-weight: bold;
            font-family: 'Segoe UI';
            
        }
        .teeth{
            color: var(--color-grey-dark-3);
        }
        select{
            border-radius: 0;
            height: 2.5rem;
            width: 10rem;
            min-width: 0;
            border: 1px solid var(--color-grey-dark-3);
            color: var(--color-grey-dark-3);
            appearance:auto;
            padding: 0.4rem;
            margin-bottom: 1rem;
        }
        .error {
          text-align: end;
          font-size: var(--font-size-4);
          padding: 0.5rem 3rem;
          color: var(--color-danger);
         // height: 2.4rem;
        }
        .datepicker {
          max-height: 2.7rem;
          max-width: 17rem;
          border-radius: 0;
          border: 1px solid var(--color-grey-dark-3);
         }
         
       
        .photos{
            display: flex;
            justify-content: center;
            align-items: center;
            border:1px dashed var(--color-grey-dark-3);
            height: 11rem;
            width: 80%;
            max-height: 10rem;
            position: relative;
          //  overflow: auto;
    .attached-imgs{
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 2px;
        position:absolute;
        justify-content: center;
        align-items: center;
        overflow:auto;
        .attach-img{
            width: 7rem;
            height: 8rem;
        }
    }
    .delete-icon {
        background-color: var(--color-white);
        // padding: 0.09rem;
        border-radius: 0.3rem;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 9%;
        box-shadow: 0 0 0.5rem var(--color-black);
        width: 1.5rem;
        height: 1.8rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }
        }
      
         .camera-icon{
            width: 4rem;
            height: 4rem;
            cursor: pointer;
          }
          input[type='file'] {
            display: none;
          }
          textarea{
            border: 1px solid var(--color-grey-dark-1);
            border-radius: 3px;
            height: 91%;
            width: 80%;
        }
        .confirm-btn{
            background-color: var(--color-main);
            max-width: 10rem;
            max-height: 2.8rem;
            margin-top: 1rem;
            margin-right: 6rem;
            font-size: 12px;
        }
      }
}