.dental-services-page-container {

    margin-right: auto;
    margin-left: auto;
    width: 1200px;
    background-color: #F2F9FF;
    border-radius: 10px;
    margin-bottom: 20px;
    max-height: calc(100vh - 150px);
    overflow: auto;
    display: flex;
    .dental-services-categories-section{
        width: 300px;
        background-color: #F8F8F8;
        max-height: calc(100vh - 150px);
        overflow: auto;
        h1{
            font-weight: bold;
            color: #646464;
            font-size: 16px;
            margin:20px 0px 20px 20px
        }
        .subfield,
        .categoryField{
            color: #7D7D7D;
            font-size: 15px;
            margin:0px 0px 5px 20px
        }
        .subfield{
            font-size: 14px;
            margin-left: 20%;
        }
    }
    .dental-services-services-section{
        background-color: #F2F9FF;
        width: 900px;
        
        table{
        width: 100%; 
        border-collapse:separate; 
        //border-spacing: 1em 0em;
        thead{
            height: 60px;
            tr{
                th{
                    width: 30%;
                }
            }
        }
        }
        .table-btn-cell{
            display: flex;
            justify-content: flex-end;
            .add-service-btn{
                
                background-color: #0090FF;
                color: white;
                border-radius: 10px;
                cursor: pointer;
                width: 80px;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
        }
        }
            .service-section-body-actions{
                display: flex;
                .edit-btn{
                    background-color: #0090FF;
                    padding: 2px 15px;
                    margin-right: 20px;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    width: 60px;
                    text-align: center;
                }
                .delete-btn{
                    width: 60px;
                    background-color: #FFEBEB;
                padding: 2px 0px;
                    margin-right: 20px;
                    color: red;
                    border-radius: 5px;
                    cursor: pointer;
                    text-align: center;
                }
            
        }
    }
  }
  @media (min-width: 768px) {
    .dental-services-page-container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .dental-services-page-container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .dental-services-page-container {
      width: 1170px;
    }
  }