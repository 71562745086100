.lab-view {
  .main-view {
    & > div {
      padding: 2rem 1rem;
    }
    .data {
      width: 30rem;
    }
    .viewer {
      flex: 1;
    }
  }
  .col-6 {
    padding: 20px;
    margin: 0px;
  }

  .container-result {
    height: calc(100vh - 110px);
    overflow-y: auto;
    background-color: var(--color-main-light);
    border-radius: 8px;
    .table {
      th,
      td {
        border: none;
        vertical-align: middle;
      }
      //   .cell-content {
      //     display: inline-flex;
      //   }

      .cell-last {
        width: 100px;
        text-align: center;
        a {
          cursor: pointer;
          img {
            height: 2rem;
          }
        }
      }
      thead {
        th {
          color: var(--color-main);
          line-height: 2.5rem;
          //   font-size: 1.3rem;
          img {
            margin: 0 5px;
            height: 2rem;
          }
        }
      }
      tbody {
        tr:nth-child(odd) {
          background-color: white;
        }
        tr {
          td {
            a.btn {
              border-radius: 4px;
              img {
                width: 20px;
                height: 20px;
              }
            }
            a.btn.active {
              background-color: var(--color-main);
            }
            a.btn.inactive {
              background-color: gray;
            }
          }
        }
        td.cell-first {
          .item-title {
            font-weight: 700;
            // font-size: 1.3rem;
            text-transform: capitalize;
          }
          .item-date {
            font-size: 1rem;
            color: gray;
          }
        }
      }
    }
  }
}
