.dental-service-modal{
    .modal-btns{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    
        div{
            margin:10px 5px 10px 0px;
            background-color: #0090FF;
            margin-right: 20px;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            width: 80px;
            height: 35px;
            text-align: center; 
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Segoe UI';
        }
        .modal-cancel-btn{
            background-color: #FFEBEB;
            color: red;
        }
    }
    .modal-header{
        display: flex;
        justify-content: space-between;
    }
    .dental-modal-content{
        display: flex;
        justify-content:flex-start;
    }
    .first-inputs-set{
        width: 50%;
        margin-top: 20px;
        p{
            font-weight: bold;
            margin-left: 10px;

        }
        input{
            height: 35px;
            margin-left: 10px;
            border-color: #0090FF;
            border-radius: 5px;
            width: 90%;
        }
        .steps-section{
            width: 100%;

            .add-more-steps{
                margin-bottom: 15px;
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: center;
            align-content: center;
                div{
                    background-color: #0090FF;
                    padding: 3px 15px;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    width: 70px;
                    text-align: center;
                    margin-right: 7%;
                    font-family: 'Segoe UI' ;
                    
                }
            }
            .step-inputs{
        
                max-height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                input{
                    width: 90%;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .second-inputs-set{
            margin-top: 20px;
            margin-left: 10%;
            input{
                height: 35px;
                border-color: #0090FF;
                border-radius: 5px;
                width: 30%;
            }
        p{
            font-weight: bold;
            margin-top: 15px;
        }
        .service-options{
            font-size:14px;
            position:relative;
            top:-15px;
            font-weight:bold;
            font-family: 'Segoe UI' ;
            input{
                width: 14px;
                position:relative;
                top:13px
            }
        }
        .lab-option-included-toggle{
            display:flex;
            justify-content:flex-start
        }
        .switch-title{
            position:relative;
            top:-10px;
        }
        /*.select-lab-service{
            display: flex;
            select{
                margin-left: 10px;
                border-color: #0090FF;
                height: 35px;
                position:relative;
                top: 5px;
            }
            i{
                color:#0090FF ;
                font-size: 16px;
                position: relative;
                left: -25px;
                top: 15px;
                cursor: pointer;
            }
        }*/
    }
    input[type='radio']:after {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        top: 8px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

    input[type='radio']:checked:after {
        width: 16px;
        height: 16px;
        border-radius: 5px;
        top: 8px;
        left: -1px;
        position: relative;
        background-color: #0090FF;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }
    p{
        font-family: 'Segoe UI'
    }
    input{
        font-family: 'Segoe UI';
    }
}
  
    .react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 10px;
  background: #E1E1E1;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  top:10px;
  left:00px;
  margin-right:10px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: -2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 10px;
}