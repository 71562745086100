.booking-details-container {
  flex: 1;
  .booking-actions {
    .btn-container {
      margin: 0 1rem;
      button {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
  &--data {
    & > div {
      margin: 0 1rem;
    }
    .mobile {
      direction: ltr;
    }
    .patient {
      flex: 1 1 40%;
      &-header {
        background-color: var(--color-main-light);
        color: var(--color-main);
        border: 2px solid var(--color-main);
        border-radius: 1rem;
        p {
          margin: 0 1rem;
        }
        &-edit {
          border-radius: 0.4rem;
          background-color: var(--color-main);
          padding: 0.5rem;
          cursor: pointer;
        }
      }
    }
    .booking {
      flex: 1 1 60%;
    }
    .patient-details,
    .booking-details {
      margin: 1rem 0;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      padding: 2rem;
      .booking-item {
        margin: 0 0 1rem 0;
        img {
          height: 1.5rem;
          width: 1.5rem;
        }
        h6 {
          margin: 0 0.5rem;
          text-align: start;
          font-family: 'GE SS Two';
        }
      }
      .table {
        & > div:nth-child(even) {
          background-color: var(--color-white);
        }
        h6 {
          font-weight: bold;
        }
      }
      .price {
        .data {
          margin: 0px 10px;

          span {
            font-size: 0.8rem;
          }
        }
        input {
          max-width: 5rem;
          height: 3rem;
        }
        .btn-container {
          margin-top: 2rem;
          margin-right: 36rem;
        }
      }
      .payment {
        .btn-container {
          margin-top: 0;
        }
        span {
          color: var(--color-main);
        }
      }
    }
  }
}
