.mapModal-container {
  position: relative;

  .address-input {
    position: absolute;
    z-index: 2;
    top: 1rem;
    // right: 5rem;
    padding: 0 1rem;
    width: 100%;
    .img {
      background-color: white;
      margin: 0 0.5rem;
      border-radius: 0.5rem;
      padding: 0 0.8rem;
      cursor: pointer;
      img {
        width: 1.5rem;
      }
    }
    .search {
      position: relative;
      border: 1px solid var(--color-white);
      background-color: var(--color-white);
      padding: 0 0.5rem;
      margin: 0 0.5rem;
      .places-list {
        list-style: none;
        position: absolute;
        top: 3rem;
        background-color: white;
        width: 100%;
        margin-left: -5px;
        li {
          border-bottom: 1px solid var(--color-grey-dark-1);
          padding: 0.5rem;
          cursor: pointer;
        }
      }
      img {
        height: 2rem;
      }
      input {
        padding: 0.5rem;
        border: none;
      }
    }
  }
  .confirm {
    position: absolute;
    bottom: 2rem;
    left: 40%;
    .btn-container {
      width: 15rem;
      // margin: 0 1rem;
      button {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
}
