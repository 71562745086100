.search-form {
    padding: 8px;
    background-color: white;
    border-radius: 3rem;
    box-shadow: 2px 3px 6px 1px rgba(214, 214, 214, 0.4);
    margin: 40px 0;
    height: 120px;
    form {
        width: 100%;
        .input-container {
            .field-container {
            }
            .error {
                display: none;
            }
        }
        .field {
            .formField {
                padding-top: 0;
                label {
                    display: none;
                }
                .input-container {
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    padding: 0 2rem;
                    input {
                        width: 100%;
                    }
                }
                .select-container {
                    .select-box {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
