.div-table{
    border-radius: 0 1rem 1rem 0;
   // margin-right: 1rem;
    overflow: auto;
    max-height: 64vh;
  }
  .table.table-responsive {
    display: inline-table;
  }
  .table {
    th,
    td {
      border: none;
      font-weight: normal;
      vertical-align: unset;
    }
    tbody {
      tr:nth-of-type(even) {
        background-color: var(--color-main-light);
      }
      tr:nth-of-type(odd) {
        background-color: var(--color-white);
      }
      
      tr {
        border-left: 11px solid transparent;
      }
      td{
        // text-align: center;
        font-size: 12px;
        font-family: 'GE SS Two';
        .input-container {
            padding: 0;
            input {
                text-align: center;
                width: 25%;
                padding: 0.2rem;
                min-width: 30px;
            }
          }
      }
    }
    thead{
        tr {
            background-color: var(--color-main-light) !important;
            th {
              max-width: 27rem;
              padding: 1.59rem 1rem;
              vertical-align: middle;
              div {
                padding: 0 1rem;
                h6 {
                  text-align: center;
                  font-size: 1.1rem;
                }
              }
              .button-actions{
                justify-content: flex-end;
                button{
                  width: 10rem;
                }
              }
            }
          }
        }
}
      