
.main-boardHeader-container {
  border-bottom: 2px solid var(--color-grey-light-4);
  flex: 0 !important;

  .page-name {
    font-size: 1.5rem;
  }

  .site-name {
    text-align: start;

    h1 {
      margin: 0;
      // font-size: 3rem;
      // font-family: 'sans-serif';
      font-weight: bold;
      color: var(--color-main);
    }
    @media only screen and (max-width: 505px) { 
      h1 {
        margin-bottom: 1rem ;
        margin-left: 1rem;
      }
    }
  }

  .clinic-data {
    flex: 1;
    width: 100%;

    div.expensesButton{
      width: 15rem;
    }
    .title {
      margin: auto;
    }
  }

  .lang {
    // align-self: flex-start;

    .info-container {
      margin: 0 2rem;

      .avatar {
        border-radius: 3rem;
        height: 3rem;
        width: 3rem;
      }

      p {
        margin: 0 0.5rem;
        color: var(--color-grey-dark-3);
        font-family: 'GE SS Two';
      }
    }
    

    .language {
      & > div {
        font-size: 1.5rem;
        border: 1px solid var(--color-main);
        cursor: pointer;
        color: var(--color-main);
        border-radius: 5px;
        padding: 0 0.5rem;
        margin: 0 1rem;
      }
    }

    img {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }
  }

  @media only screen and (max-width: 505px) { 
    .lang{
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .add-patient,
  .add-unit {
    button {
      img {
        height: 1.5rem;
        width: 1.5rem;
      }

      span {
        margin: 0 0.5rem;
      }

      padding: 0 0.6rem;
      font-size: 1.1rem;
      color: var(--color-white);
      border: none;
      // border-radius: 5px;
      background: var(--color-main);
      outline: none;
      cursor: pointer;
      height: 100%;
      // margin: 0 1rem;
    }
  }

  .btn.btn-default.gift-btn,
  .btn.btn-default.gift-btn:hover,
  .btn.btn-default.gift-btn:active {
    box-shadow: none;
    border: none;
  }

  .gift-btn {
    position: relative;
    background-color: var(--color-main-light);
    margin: 0 8px;

    svg .c {
      fill: var(--color-main);
    }

    .notification {
      position: absolute;
      width: 6px;
      height: 6px;
      background: red;
      top: -2px;
      right: -2px;
      border: none;
      padding: 0;
    }

    .popOver {
      position: absolute;
      right: 0;
      width: 90px;
      background-color: white;
      box-shadow: 0px 4px 8px #00000014;
      border-radius: 8px;
      opacity: 1;
      padding: 12px 8px;
      bottom: -80px;
      z-index: 1;

      .btn.btn-primary {
        background-color: var(--color-main);
        border-color: var(--color-main);
      }

      h3 {
        color: var(--color-main);
        text-transform: capitalize;
      }

      small {
        font-size: 10px;
        margin: 4px;
      }
    }
  }

  .add-unit {
    button {
      border-radius: 5px;
      font-size: 1.2rem;
      padding: 0.5rem;
      width: 100%;
    }
  }
}

[dir='rtl'] {
  .main-boardHeader-container {
    .site-name {
      h1 {
        font-family: 'Segoe UI'; //!important;
      }
    }
  }
}
