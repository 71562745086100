.date-picker {
  .text-success {
    color: var(--color-main);
    background-color: var(--color-white);
  }
  .text-error {
    color: var(--color-danger);
    background-color: var(--color-white);
  }
  .error {
    text-align: start;
    font-size: var(--font-size-4);
    padding: 0.5rem 0;
    color: var(--color-danger);
    height: 2.4rem;
  }
}
