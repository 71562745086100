.service-details-wrapper {
  margin-left: 1em;
  * {
    font-family: 'Segoe UI';
  }
  h1 {
    font-size: 1.7em;
    cursor: default;
    //color: var(--color-main);
    color: var(--color-grey-dark-7);
  }

  .active-header {
    color: var(--color-main);
  }
  .discount-input{
    display: flex;
    align-items: center;
    input{
      height: 35px;
      margin-right: 20px;
    }
  }
  input[type="checkbox"] {
    height: 17px;
    width: 17px;
  }
 
  .pecentage-checkbox{
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    p{
      //margin-left: 5px;
      position: relative;
      color: var(--color-grey-dark-7);
      top: -2px;
      left: -15px;
      font-size: 1.5em;
      font-family: 'Segoe UI';
    }
  }
}
