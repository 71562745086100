.booking-list-item {
    transition: transform 0.3s ease-in;
    padding: 0.5rem 1rem;
    height: 4rem;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;


    .avatar {
        height: 30px;

        &+p {
            margin: 0 1rem;
        }
    }

    p {
        line-height: 16px;
        font-family: 'GE SS Two';
        font-size: var(--font-size-3);
        text-transform: capitalize;
    }

    .age {
        direction: ltr;
    }

    .tag {

        width: 30px;
        height: 30px;
        display: contents;

        span {
            width: 20px;
            height: 20px;
            color: var(--color-main);
            background-color: #ffffff;
            border-radius: 50%;
            text-align: center;
            font-weight: bold;
            margin: 5px;
            font-size: 12px;
        }
    }

    .tag.payment {
        span {
            color: #ffffff;
            background-color: #FF1654;
        }
    }

    .tag.status {
        span {
            font-size: 11px;

            svg {
                width: auto;
                height: 10px;
            }
        }

        &.completed {}

        &.active {}

        &.hold {
            span {
                svg {
                    // width: 9px;
     // height: 10px;

                }
            }
        }

        &.new {
            opacity: 0;
        }
    }
}