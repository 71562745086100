.complete-profile-form {
  flex: 1 1 50%;
  width: 100%;
  padding: 2rem 3rem;
  .complete-profile-form-container {
    flex: 1;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    // max-width: 50rem;
    padding: 2rem 1rem;
    .name-group {
      img {
        height: 2rem;
        width: 3rem;
      }
    }
    .input-container {
      width: 90%;
    }
    .btn-container {
      width: 60%;
      margin: auto;
    }
    .password-container {
      & > div {
        flex: 1;
      }
    }
    form {
      width: 100%;
    }
  }
}
