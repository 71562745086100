.report-header{
    max-height: 5rem;
    .title{
        font-size: 14px;
        font-family:  'Myriad Pro';
    }
    
    .print{ 
       a{
        border-radius: 0.5rem;
       }
    }
     .close{
        margin: 0.5rem;
        margin-left: 3rem;
        img{
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;
        }
    
    }
}

.report-body{
    table{
        overflow: auto;
      }
      .table.table-responsive {
        display: inline-table;
      }
      .table {
    
        td {
          border: none;
          font-weight: normal;
        //  vertical-align: unset;
        }
        tbody {
          tr:nth-of-type(even) {
            background-color: var(--color-main-light);
          }
          tr:nth-of-type(odd) {
            background-color: var(--color-white);
          }
          tr {
            border-left: 11px solid transparent;
          }
          td{
            text-align: center;
          }
        }
        thead{
            tr {
                background-color: var(--color-main-light) !important;
                th {
                  font-family: 'Myriad Pro';
                  font-size: 14px;
                  max-width: 27rem;
                  padding: 1.59rem 1rem;
                  vertical-align: middle;
                  text-align: center;
                  font-weight: bold;
               
                }
              }
            }
    }
}
   