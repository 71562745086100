.examinationTypes-container_examine {
  font-size: 1.3rem;
  border-radius: 1rem;
  min-width: 416px;
  //   border: 1px solid var(--color-main);
  .network {
    &.network-selected {
      input {
        border: 1px solid transparent;
      }
      input:focus {
        border: 1px solid var(--color-main);
      }
    }
    span {
      position: absolute;
      right: 5px;
      display: block;
      top: 8px;
    }
    select,
    input {
      position: relative;
      border: 1px solid var(--color-main);
      color: var(--color-main);
    }

    .col-5,
    .col-1 {
      padding: 0px;
    }
    .offer-action-button {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .input {
    width: 6rem;
    height: 3.5rem;
    text-align: center;
    border: 1px solid var(--color-main);
    border-radius: 3px;
    font-size: 1.4rem;
    color: var(--color-grey-dark-1);
    direction: ltr;
    &::placeholder {
      color: var(--color-grey-dark-1);
    }
  }
  .custom-name {
    width: 20rem;
  }
  &-inputs {
    & > div {
      text-align: start;
    }
  }
}
