.register-page {
  flex: 1;
  .register-page__main {
    flex: 1;
    padding: 1rem 2rem;
  }
  .clinicImage-container {
    flex: 1 1 50%;
    .progress-container {
      width: 81%;
      margin: auto;
      text-align: start;
      .progress {
        width: 60%;
        direction: ltr;
        border: 1px solid var(--color-main);
        border-radius: 5px;
        background-color: var(--color-white);
        .progress-bar {
          background-color: var(--color-main);
        }
      }
      .percent {
        color: var(--color-main);
      }
    }
    img {
      width: 95%;
      height:70vh !important;
      max-height: 70vh;
      margin: auto;
      
    }
    @media only screen and (max-width: 575px) { 
      img {
        height:35vh !important;
        max-height: 35vh;
        margin: auto;
      
      }
    }
    .neqabaImg {
        max-width: 500px;
        height: auto;
        margin: 5%;
        max-height: 308px;

    }
  }
}
