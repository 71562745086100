.email-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  .icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    left: 3%;
    top: 19%;
    cursor: pointer;
  }
  .error {
    text-align: start;
    font-size: var(--font-size-4);
    padding: 0.5rem 0;
    color: var(--color-danger);
    height: 2.4rem;
  }
  input {
border: 1px solid #0090FF;
  padding-left: 3.3rem;
      line-height: 2rem;
    }
}



[dir='rtl'] {
.email-input-container  {
    .icon {
      right: 3%;
      left: unset;
    }
  }
}
