.register-form {
  flex: 1 1 50%;
  width: 100%;
  padding: 2rem 3rem;

  .error {
    text-align: start;
    font-size: var(--font-size-4);
    padding: 0.5rem 0;
    color: var(--color-danger);
    height: 2.4rem;
  }

  .register-form-container {
    flex: 1;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    padding: 2rem 1rem;

    .name-group {
      margin: 0 1rem;

      img {
        height: 1.8rem;
        width: 3rem;
      }
    }

    .name-container {
      h6,
      input {
        font-family: 'GE SS Two';
        font-size: 1rem;
      }

      .input-container,
      .select-container {
        .error {
          text-align: end;
        }
      }
    }

    .drop-zone {
      &-img {
        height: 20rem;
        //width: 20rem;
      }
    }

    .input-container,
    .select-container {
      // width: 90%;
      margin: 0 1rem;
    }

    // .input-container {
    //   input[type='text'] {
    //     text-align: end;
    //     &::placeholder {
    //       text-align: start;
    //     }
    //   }
    // }

    .btn-container {
      width: 60%;
      margin: auto;
    }

    .password-container {
      & > div {
        flex: 1;
      }

      .license {
        // align-self: flex-start;
        // width: 50%;
        input.title::placeholder {
          text-align: right;
          font-family: 'GE SS Two';
          font-size: 1rem;
        }

        input.title::-moz-placeholder {
          text-align: right;
          font-family: 'GE SS Two';
          font-size: 1rem;
        }

        input.title:-moz-placeholder {
          text-align: right;
          font-family: 'GE SS Two';
          font-size: 1rem;
        }

        input.title:-ms-input-placeholder {
          text-align: right;
          font-family: 'GE SS Two';
          font-size: 1rem;
        }

        input.title::-webkit-input-placeholder {
          text-align: right;
          font-family: 'GE SS Two';
          font-size: 1rem;
        }
      }

      // .medicalDegContainer {
      //   min-width: 17em;
      // }
      // .specialtyContainer {
      //   //   min-width: 33em;
      //   text-overflow: ellipsis;
      // }
    }

    form {
      width: 100%;
    }
  }

  .create-account {
    .check {
      label {
        font-size: 1.2rem;
        margin: 0;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 1rem;
      }
    }

    // .btn-container {
    //   width: 60%;
    //   margin: auto;
    // }
  }

  // input.title {}
  .drop-zone {
    cursor: pointer;
  }
}
