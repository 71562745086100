.drugs-container {
  direction: ltr;
  .card-container {
    padding: 20px;
    background-color: white;
    border-radius: 3rem;
    box-shadow: 2px 3px 6px 1px rgba(214, 214, 214, 0.4);
    min-height: 70vh;
    .card-header {
      text-align: center;
      color: lightseagreen;
      background: transparent;
      border: none;
      a {
        border-bottom: 1px solid lightseagreen;
        font-size: 3rem;
      }
      button {
        background: none;
        outline: none;
        border: none;
        color: var(--color-main);
        font-size: 1.2rem;
        cursor: pointer;
        font-family: inherit;
      }
    }
    .card {
      margin: 8px;
      direction: ltr;
      .card-body {
        .drug-name {
          padding: 2px 10px;
          font-size: 1.2rem;
          display: inline-flex;
        }
        .btn {
          float: right;
          padding: 2px 10px;
          font-size: 1.2rem;
        }
      }
    }
  }
}
