.no-employees {
  .description {
    .descriptionText {
      border-radius: 1rem;
      background-color: var(--color-main-light);
      color: var(--color-main);
    }
  }
  &-data {
    border-radius: 1rem;
    background-color: var(--color-main-light);
    img {
      height: 10rem;
    }
    p {
      font-size: 1.3rem;
      margin-top: 1rem;
      max-width: 13rem;
      text-align: center;
    }
  }
  .btn-container {
    margin-top: 2rem;
  }
}
