.booking-details {
  min-width: 25rem;
  .booking-data {
    // flex: 1;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 2rem;
    &__patient {
      &-data {
        .name-group {
          margin: 1rem 0;
          margin-bottom: 2rem;
        }
      }
      &-lastVisit {
        color: var(--color-main);
        .date {
          margin: 0 1rem;
        }
      }
    }
  }
}
