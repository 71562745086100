.profile-images {
  flex: 1 1 50%;
  .profile-images-container {
    flex: 1;
    .nat-num-profile > div {
      flex: 1;
      padding: 0 1rem;
    }
    background-color: var(--color-main-light);
    border-radius: 1rem;
    // padding: 1.7rem 1rem;
    padding-bottom: 0;
    p {
      text-align: start;
    }
    .name-attr {
      height: 4rem;
    }
    input[type='file'] {
      display: none;
    }
    .drop-zone {
      &-img {
        height: 10rem;
        width: 10rem;
      }
    }
    .profile {
      padding: 10px;
      background: white;
      border-radius: 3rem;
      cursor: pointer;
      img {
        width: 3rem;
        height: 3rem;
      }
    }
    .pic {
      img {
        height: 8rem;
        width: 10rem;
      }
    }
    .national-id {
      .nat-num {
        text-align: start;
        .error {
          height: 1.8rem;
          span {
            color: var(--color-danger);
          }
        }
      }
      input {
        padding: 0.7rem;
      }
      &-img {
        .upload {
          border-radius: 1rem;
          span {
            max-width: 10rem;
            text-align: center;
          }
        }
      }
    }
    .percent {
      font-size: var(--font-size-3);
      padding: 2px;
      background: var(--color-white);
      border-radius: 5px;
      color: var(--color-main);
    }
  }

  .create-account {
    .btn-container {
      width: 60%;
      margin: auto;
    }
  }
}
