.testType-container {
  border-radius: 1rem;
  background-color: var(--color-main-light);
  // font-size: 1.3rem;
  padding: 1rem 2rem;
  &-title {
    cursor: pointer;
    &-upArrow {
      transform: rotateX(180deg);
    }
  }
  .testType-container-test-subTest {
    flex: 1;
    .name {
      margin: 0.5rem;
    }
  }
  &-test {
    display: flex;
    flex: 1;
    width: 100%;
    &-subTest {
      display: grid;
      grid-row-gap: 1rem;
      grid-column-gap: 2rem;
      grid-template-columns: 48% 48%;
      color: var(--color-main);

      input {
        flex: inherit;
      }
    }
  }
}
