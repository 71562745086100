.modal-container{
   // width: 20rem;
    .close {
        height: 1rem;
        cursor: pointer;
        margin: 0 0.5rem;
    }
    .btn{
      margin-left: 3px;
      width: 24rem;
      border-radius: 5px;
    }
    .btn:hover {
        box-shadow: none;
        border-radius: 5px;
        background-color: var(--color-tertiary-light);
    }
    .btn:active {
      width: 180px;
      color: #0090ff;
      background-color: white;
    }
   
   .ok{
       margin-top:18rem;
       position: fixed;
       width: 5rem;
   }
   .clinicImg {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 5px;
    margin-bottom: 9px;
  }
 
}