.previous-booking {
  p {
    padding: 0 1rem;
  }
  &-container {
    &-details {
      margin: 1.5rem;
      border-radius: 1rem;
      padding: 2rem 1rem;
      width: 18rem;
      .previous-booking-item {
        margin: 0 0 1rem 0;
        h6 {
          margin: 0 0.5rem;
        }
        .edit {
          border-radius: 0.4rem;
          background-color: var(--color-main);
          cursor: pointer;
          height: 2rem;
          width: 2rem;
          padding: 0.2rem;
        }
      }
    }
    .prev {
      background-color: var(--color-grey-dark-5);
    }
    .comming {
      background-color: var(--color-main-light);
    }
  }
}
