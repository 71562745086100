.procedures-component {
  .title {
    font-size: var(--font-size-2);
    font-weight: 700;
  }
  .procedures-container {
    overflow: auto;
    max-height: 14rem;
    width: 100%;
  }
  .img-container {
    margin-bottom: 1rem;
    position: relative;
    img {
      height: 2rem;
    }
    .main-img {
      cursor: pointer;
      background-color: var(--color-main-light);
      border-radius: 0.5rem;
      overflow: hidden;
      width: 12rem;
      height: 9rem;
      margin: 0 1rem;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .delete-icon {
      background-color: var(--color-white);
      // padding: 0.09rem;
      border-radius: 0.3rem;
      cursor: pointer;
      position: absolute;
      top: 5%;
      right: 9%;
      box-shadow: 0 0 0.5rem var(--color-black);
      width: 1.5rem;
      height: 1.8rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    span {
      width: 7rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .add-img {
    background-color: var(--color-main-light);
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0.5rem 0.9rem;
    input[type='file'] {
      display: none;
    }
    p {
      margin: 0 0.5rem;
      font-size: 1rem;
    }
    img {
      height: 1.7rem;
    }
  }
}
.add-photo-container{
   
  width: calc((100vw - 35rem) / 2);
  height: 9rem;
  border-radius: 1rem;
  overflow:auto;
  input[type='file'] {
    display: none;
  }
  .add-photo{
    background-color: var(--color-main);
    height: 8rem;
    width: 7rem;
    border-radius: 1rem;
    text-align: center;
    cursor: pointer;
    .plus{
      font-size: 3rem;
      font-weight: bold;
    }
    .txt{
      position: relative;
      bottom:1rem;
    }

  }
  .loading-img{
    width: 7rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
   
    img{
      height: 50%;
      width: 50%;
      -webkit-transition: all 0.5s ease-in;
      -webkit-animation-name: rotate; 
      -webkit-animation-duration: 1.0s; 
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
    	
    	transition: all 0.5s ease-in;
      animation-name: rotate; 
      animation-duration: 1.0s; 
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
    @keyframes rotate {
      from {
          transform: rotate(0deg);
      }
      to { 
          transform: rotate(360deg);
      }
  }
  @-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}
  }

  
    .delete-icon {
      background-color: var(--color-white);
      // padding: 0.09rem;
      border-radius: 0.3rem;
      cursor: pointer;
      position: absolute;
      top: 5%;
      right: 9%;
      box-shadow: 0 0 0.5rem var(--color-black);
      width: 1.5rem;
      height: 1.8rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .photo{
    width: 7rem;
    border-radius: 1rem;
    text-align: center;
    cursor: pointer;
    position: relative;
    .main-img{
      cursor: pointer;
      background-color: var(--color-main-light);
       border-radius: 1rem;
      overflow: hidden;
      width: 7rem;
      height: 8rem;
      img {
        height: 100%;
        width: 100%;
      }
    }
 
}


