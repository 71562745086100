.history-view {
  padding: 3%;
  .header {
    ul {
      box-shadow: 0px 4px 2px -2px lightgrey;
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      li {
        //margin-right: 2rem;
        padding: 1rem;
        font-size: var(--default-font-size);
        font-weight: bold;
        cursor: pointer;
        font-size: 15px;
      }
      .selected-li {
        border-style: solid;
        border-width: 0 0 0.4rem 0;
        border-color: var(--color-main);
      }
    }
  }

  .main-content {
    // background-color: green;
    .complain-wrapper {
      padding-top: 2%;
      .section {
        font-size: var(--default-font-size);

        textarea {
          width: 90%;
          min-height: 15rem;
        }
      }
      button {
        margin: 2rem;
        width: 17rem;
        height: 4rem;
        border-radius: 1rem;
        background-color: var(--color-main);
        color: var(--color-white);
        font-size: var(--font-size-1);
        font-weight: lighter;
        border-style: none;
      }
    }
  }
}
