.login-user-page-container {
  flex: 1 1 40%;

  .image-name {
    align-items: center;
    & > div {
      margin: 0 1rem;
    }
    img {
      width: 5rem;
      height: 5rem;
      border-radius: 2.5rem;
    }
    a {
      text-decoration: underline;
      &:hover {
        color: var(--color-main);
      }
    }
  }

  .login-user-page-form {
    & > div {
      flex: 1;
      background-color: var(--color-main-light);
      border-radius: 1rem;
      max-width: 35rem;
    }
    .lock-icon {
      img {
        height: 1.7rem;
        margin: 0px 4px;
      }
    }
    form {
      width: 100%;
      .passwordInput {
        position: relative;
        .eyeIcon {
          position: absolute;
          top: 1rem;
          left: 1rem;
          img {
            height: 2rem;
          }
        }
      }
      .forget {
        a {
          color: var(--color-grey-dark-1);
        }
      }
    }
  }
}
