.upcomming-booking-details-container {
  .booking {
    flex: 1;
  }
  .btn-container {
    margin-top: 0;
  }
  .patient-headerr {
    background-color: var(--color-main-light);
    color: var(--color-main);
    border: 2px solid var(--color-main);
    border-radius: 1rem;
    p {
      margin: 0 1rem;
    }
    &-edit {
      border-radius: 0.4rem;
      background-color: var(--color-main);
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .booking-details {
    margin-top: 0.8rem;
    background-color: var(--color-main-light);
    border-radius: 1rem;
    padding: 0 2rem;
    .booking-item {
      margin: 2rem 0;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
      h6 {
        margin: 0 1rem;
        text-align: start;
      }
    }
    .payment {
      span {
        color: var(--color-main);
      }
    }
  }
}
