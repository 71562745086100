.clinicDetails-container {
  flex: 1 1 70%;
  
  img {
    width: 95%;
    height:70vh !important;
    max-height: 70vh;
    margin: auto;
  }
  @media only screen and (max-width: 575px) { 
    img {   
      width: 95%; 
      height:35vh !important;
      max-height: 35vh ;
      margin: auto;
    
    }
  }
}
