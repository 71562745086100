.emptyPage {
    display: flex;
    justify-content: center;

    .pageBody {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .icon-container.error {
            background: #FFECF1 0% 0% no-repeat padding-box;

            .a {
                stroke: #FFECF1;
            }
        }

        .icon-container {
            height: 212px;
            min-width: 212px;
            background: #F2F9FF 0% 0% no-repeat padding-box;
            border-radius: 12px;
            opacity: 1;
            margin-bottom: 16px;

            img,
            svg {
                width: 172px;
                height: 172px;
                margin: 20px;
            }
        }

        h2 {
            text-align: center;
            color: var(--color-grey-dark-2);
        }

        .text {
            color: var(--black);
        }

        .renewBtn {
            width: 200px;
            margin-top: 18px;
            padding: inherit;
            line-height: 50px;
            font-size: 2rem;
            border-radius: 12px;
        }

    }

    .error-message {
        color: red;
    }

}

.plan-log {
    font-size: 1rem;
    color: #a7a7a7;

    .wallet {
        width: 16px;
        height: 16px;
        margin: 0 4px 4px 0px;
    }

    a {
        margin-right: 10px;
    }

    a.link {
        text-decoration: underline;
        margin-left: 12px;
        color: var(--color-main);
        font-size: 12px;
        line-height: normal;
    }
}