.card-deck {
  text-align: center;
  margin-top: 40px;
  justify-content: center;

  .card {
    max-width: 300px;
  }
}

.plan-form {
  .plan-form-title {
    h1,
    h3 {
      margin-bottom: 16px;
    }

    h1 {
    }

    h3 {
      color: #0090ff;
    }
  }

  .error {
    color: red;
    line-height: 20px;
  }

  .btn-group-toggle {
    width: 360px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;

    .btn:active,
    .btn {
      width: 180px;
      color: #0090ff;
      background-color: white;
      height: 40px;
      font-size: 16px;
      border: none;
    }

    .btn:first-child {
      //   margin-right: 10px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .btn:last-child {
      //   margin-left: 10px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .btn:hover {
      box-shadow: none;
      border: none;
    }

    .btn.active:active,
    .btn.active {
      color: white;
      background-color: #0090ff;
      border-radius: 8px;
    }
  }

  .plan-form-body {
    padding-top: 20px;
    display: flex;
    justify-content: center;

    .plan-body {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 8px #00000014;
      border-radius: 12px;
      opacity: 1;
      width: 200px;
      // height: 300px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      justify-content: space-evenly;
      margin: 20px;
      position: relative;

      .plan-logo {
        margin: auto;
        margin-top: 42px;
      }

      .plan-note {
        background-color: #31b260;
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        border-radius: 0px 11px;
        color: white;
      }

      .plan-data {
        list-style: none;
        text-align: left;
        color: #a7a7a7;
        // margin: 8px -8px;

        li {
          line-height: 20px;

          svg {
            margin-right: 8px;
          }
        }
      }

      img {
        margin-bottom: 20px;
      }

      .form-check-label {
        font-weight: bolder;
        text-align: left;
        margin: 4px 0px;
        font-size: 16px;

        strong {
          color: #0090ff;

          input {
            width: 64px;
            height: 24px;
            text-align: center;
          }
        }

        small {
          font-size: 60%;
        }
      }

      .options-wrapper {
        text-align: left;
      }
    }
  }

  .plan-form-footer {
    text-align: left;
    margin-top: 20px;
    margin: auto;

    .footer-header {
      text-align: center;
    }

    .footer-sub-header {
      text-align: center;
      color: #007bff;
    }

    .terms {
      label {
        text-decoration: underline;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .controller {
      width: 300px;
      margin: auto;

      .error {
        padding: 0px;
      }
    }

    .controller-price {
      width: 200px;
      margin: auto;

      input {
        background-color: #ebf6ff;
        border: 1px solid #0090ff;
        text-align: center;
      }
    }

    .input-group {
      border: 1px solid #007bff;
      border-radius: 8px;
      overflow: hidden;
      height: 40px;
      margin-top: 20px;

      .input-group-prepend {
        .input-group-text {
          background-color: transparent;
          border: none;
        }
      }

      .form-control:focus {
        box-shadow: none;
      }

      .form-control {
        border: none;
        width: 220px;
        height: 35px;
      }
    }

    .btn-container.submit-btn {
      text-align: center;
      margin-top: 4px;

      button {
        border: 2px solid #0090ff;
        border-radius: 8px;
        height: 40px;
        width: 250px;
      }
    }
  }
}
