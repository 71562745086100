.booking-item-date {
  text-align: center;
  font-weight: bold;
  font-size: 11px;
}

.booking-item-container {
  border-radius: 1rem;
  width: 32rem;
  overflow: hidden;
  margin: auto;
  margin-bottom: 1.2rem;
  .line {
    font-family: sans-serif;
    text-align: center;
    font-size: 12px;
    max-width: 600px;
    position: relative;
  }
  .line1:before {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background: #0090ff;
    position: absolute;
    left: 0;
    top: 30%;
    z-index: -2;
  }
  .line2:after {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background: #0090ff;
    position: absolute;
    right: 0;
    top: 30%;
    z-index: -2;
  }
  .date-time {
    padding: 0.8rem 1rem;
    color: var(--color-white);
    .numbers {
      p {
        font-weight: bold;
      }
    }
    img {
      // height: 1.7rem;
      margin: 0 0.5rem;
      cursor: pointer;
    }
  }

  &-details {
    .booking-item {
      position: relative;
      padding: 0.5rem 1rem;
      height: 4.5rem;
      &:not(:last-child) {
        border-bottom: 1px solid var(--color-grey-dark-6);
      }
      cursor: pointer;
      span {
        color: var(--color-grey-dark-1);
      }
      button {
        background-color: Transparent;
        border: none;
        outline: none;
        img {
          height: 1.7rem;
        }
        span {
          color: var(--color-main);
          font-size: var(--font-size-1);
          margin: 0 0.5rem;
        }
      }
    }
    .current {
      &::before {
        content: '';
        width: 0.7rem;
        height: 100%;
        // color: #0090ff;
        background-color: var(--color-main);
        position: absolute;
        right: 0;
      }
    }
    .disabled-item {
      opacity: 0.4;
      // cursor: unset;
    }
    .avatar {
      height: 4rem;
      & + p {
        margin: 0 1rem;
      }
    }
    .avatar1 {
      height: 2.5rem;
      & + p {
        margin: 0 1rem;
      }
    }
    .btn-round {
      border: 1px solid transparent;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background-color: red;
      color: white;
    }
  }
  .icons {
    span {
      width: 2rem;
      height: 2rem;
      // color: var(--color-main);
      background-color: var(--color-white);
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      margin: 5px;
      font-size: 12px;
    }
    button {
      width: 2rem;
      height: 2rem;
      // color: var(--color-main);
      background-color: var(--color-white);
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      margin: 5px;
      font-size: 12px;
    }
    .span-pending {
      width: 2.5rem;
      height: 2.5rem;
      padding: 3px 0px 3px 0px;
    }
    .payment {
      color: var(--color-white);
      background-color: red;
    }
    .pending-confirm {
      color: var(--color-white);
      background-color: var(--color-main);
      svg {
        width: 60%;
        height: 100%;
      }
    }
    .pending-cancel {
      svg {
        width: 125%;
        height: 125%;
      }
    }
    .status {
      // font-size: 11px;
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    .hold {
      svg {
        height: 1.1rem;
      }
    }
    .new {
      opacity: 0;
    }
    .pending {
      opacity: 0;
    }
  }
}
@media only screen and (max-width: 1336px) {
  [class*='booking-item-container'] {
    .icons {
      .span-pending {
        padding: 1px;
      }
      // .pending-cancel {
      //   svg {
      //     width: 25px;
      //     height: 25px;
      //   }
      // }
      // .pending-confirm {
      //   svg {
      //     width: 13px;
      //     height: 23px;
      //   }
      // }
    }
  }
  svg.network-icon {
    margin: 0 4px;
  }
}
