.total-income-details-card{
    width: 250px;
    background-color: #F2F9FF;
    border-radius: 10px;
    margin-top: 20px;
    padding:10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    div{
        display: flex;
        justify-content: space-between;
        p{
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
            font-size: 12px;
        }
    }
}