.expModal-container {
  position: relative;
  .title {
    width: 100%;
  }
  .input{
    width: 70%;
    .passwordInput {
      position: relative;
      select{
        width: 100%;
        border-color: #0090FF;
      }
      input{
        border-color: #0090FF;
      }
      .arrow {
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        pointer-events: none;
      }
    }
  }
  .confirm {
    position: fixed;
    bottom: 2rem;
    left: 40%;
    .btn-container {
      width: 15rem;
      // margin: 0 1rem;
      button {
        font-size: 1.2rem;
        padding: 0.7rem 1.5rem;
      }
    }
  }
}
