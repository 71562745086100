.setting-page {
  flex: 1;
  .segment {
    margin: 0;
    cursor: pointer;
  }
  &__main {
    flex: 1;
  }
  .button-actions {
    .btn-container {
      width: 10rem;
      margin: 1rem;
      svg {
        height: 1.6rem;
        margin: auto;
      }
    }
  }
}
