.patient-form {
  background-color: var(--color-main-light);
  border-radius: 1rem;
  .ar {
    input {
      font-family: 'GE SS Two';
      direction: rtl;
    }
  }
  .name {
    margin: 1rem 0;
    & > div {
      flex: 1;
      padding: 0 0.5rem;
    }
    .mobile {
      .input-container {
        input {
          direction: ltr;
        }
      }
    }
  }
  img {
    height: 1.5rem;
  }
  h6 {
    margin: 0 0.5rem;
    span {
      color: var(--color-grey-dark-3);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container,
  .input-container,
  .select-container {
    input,
    select {
      width: 100%;
      color: var(--color-main);
      text-align: center;
    }
    select {
      text-align-last: center;
    }
  }
  .arrow {
    top: 20%;
  }
  .age-container {
    select {
      text-align-last: center;
      background: var(--color-main-light);
      color: var(--color-main);
    }
  }
}
