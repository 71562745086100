.upload-containerr {
  position: relative;
  min-width: 164px;
  margin: 0 23px 0 8px;
  border-radius: 0.5rem;

  .d-flex.align-items-center.error {
    width: 40px;
    height: 20px;
  }

  .upload-item {
    position: relative;
    cursor: pointer;
    //   width: 15rem;
    margin: auto;
    border: 1px solid var(--color-main);
    border-radius: 4px;
    padding: 0.5rem;
    font-size: var(--font-size-2);
    outline: none;
    height: 3.5rem;
    min-width: 171px;
    img {
      height: 1rem;
      width: 1rem;
    }
    .items {
      min-width: 152px;
    }
    .upload {
      height: 2rem;
      width: 2rem;
      margin: 0 0.5rem;
    }

    .warning {
      margin: 0 1rem;
    }

    .progress {
      width: 100%;
      direction: ltr;
      margin: 0.5rem 0;

      .progress-bar {
        background-color: var(--color-main);
      }
    }
  }

  .upload-items {
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    z-index: 1;
  }
}

.upload-containerr.opened {
  .upload-item {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .upload-items {
    border: 1px solid var(--color-main);
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .upload-item {
      border: none;
      height: 4.7rem;

      .upload-item-header {
        p {
          line-height: 13px;

          img.upload.warning {
            height: 10px;
            float: right;
          }
        }
      }
    }
  }
}
