.operations-wrapper {
  height: 450px;
  overflow: auto;
  h1 {
    font-size: 16px;
    font-weight: bold;
  }
  .operation-drop-down {
    background-color: white;
    border-radius: 5px;
    width: 150px;
    height: 70px;
    position: absolute;
    right: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    div {
      font-size: 16px;
      margin-left: 10px;
      margin-top: 5px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .operation-specifics {
    display: flex;
    justify-content: space-between;
    .WL-MF-fields {
      margin-left: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
      .WL-MF-canals {
        height: 40px;
        text-align: center;
        border: grey solid 1px;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        margin-left: 10px;
        margin-right: 10px;
        align-items: center;

        h1 {
          color: grey;
          margin-top: 8px;
          margin-left: 10px;
          font-size: 14px;
          margin-right: 10px;
        }
        input {
          height: 30px;
          border: none;
          width: 80px;
        }
      }
      .WL-MF-length,
      .WL-MF-size {
        height: 40px;
        text-align: center;

        display: flex;
        justify-content: space-between;

        margin-left: 10px;
        margin-right: 10px;
        align-items: center;
        h1 {
          color: grey;
          margin-top: 8px;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 14px;
        }
        input {
          height: 30px;
          border: none;
          width: 30px;
          border: grey solid 1px;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  .operation-item {
    position: relative;
    .operation-step {
      display: flex;
      label {
        white-space: nowrap;
      }
      .order-action {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        button {
          color: var(--color-white);
          width: 4.5rem;
          height: 1.7rem;
          padding: 0;
        }
        .remake {
          background-color: var(--color-red-2);
        }
        .approve {
          margin: 0 1rem;
        }
      }
    }
  }
  .operation-header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .operation-actions {
      display: flex;
      align-items: center;
      .action-btn {
        background-color: var(--color-main);
        padding: 5px 10px;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
      }
      .more-icon {
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .headerDivider {
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .operation-working-teeth {
    color: #777777;
  }
  .operation-progress-bar {
    width: 201.5px;
    margin-bottom: 20px;
    border: 0.5px solid rgb(192, 192, 192);
    border-radius: 50px;
  }
  .in-progress-operation {
    color: #00c527;
  }
  .progress-bar-title {
    color: var(--color-main);
    font-size: 10px;
    position: relative;
    bottom: 15px;
  }
  [type='checkbox'] {
    margin-right: 5px;
  }
  label {
    font-size: 14px;
  }
}
