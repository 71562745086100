.custom-alert-ui {
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);

  // color: var(--color-main);
  h4 {
    font-size: var(--font-size-1);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      font-size: 1.2rem;
      padding: 0.7rem 1rem;
      height: 29px;
      line-height: 12px;
    }
  }
}