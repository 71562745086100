.vital-signs-component {
    overflow: auto;
    max-height: 33vh;
    margin-bottom: 10px;
    .form-container {
         max-width: 94%;
    }
    .vital-item {
        .col-sm-1 {
            line-height: 30px;
            text-align: left;
            padding: 0px;
        }
        .span-bottom{
            vertical-align: text-top;
        }
        .col-sm-2,
        .col-sm-3,
        .col-sm-7,
        .col-sm-6 {
            padding-right: 8px;
            padding-left: 8px;
            min-height: 17%;

            input.form-control {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid var(--color-main);
                border-radius: 7px;
                opacity: 1;
                height: 30px; 
                min-width:40px;
            }
            input.form-control.error {
                border: 1px solid var(--color-danger);
            }
            input.form-control.error:focus {
                border-color: var(--color-danger);
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
            }
        }
    }
}
.Normal {
	color: #31B260;
}
.High {
	color: #FF1654;
}
.Low {
	color: #0090FF;
}
.Stage1 {
	color: #f08e38;
}
.Stage2 {
	color: #ee6f5d;
}

@media only screen and (max-width: 1336px) {
    [class*="col-form-label"] {
        margin-right: 28px;
    }
  }
  @media only screen and (max-width: 1400px) {
    [class*="col-sm-2"] {
        margin-right: 23px;
    }
  }
  // [class*="form-container"] {
    //     max-width: 100%;
    // }
//   @media only screen and (max-width: 1180px) {
//     [class*="form-container"] {
//         max-width: 200px;
//     }
//   }
//   @media only screen and (max-width: 1250px) {
//     [class*="form-container"] {
//         max-width: 200px;
//     }
//   }
  //form-container