.finances-header {
  border-bottom: 1px solid var(--color-grey-light-4);
  width: 100%;
  .switch-container {
    margin: 8px 15px 8px 80px;
    border: 1px solid var(--color-main);
    border-radius: 4px;
    cursor: pointer;
    height: 37px;
    .day {
      background-color: var(--color-white);
      color: var(--color-main);
      padding: 0.7rem;
      align-self: stretch;
      width: 6.5rem;
      text-align: center;
      border-radius: 4px;
    }
    .select {
      background-color: var(--color-main);
      color: var(--color-white);
      border: none;
    }
  }
  .monthly-container {
    overflow-x: auto;
    overflow-y: hidden;
    .monthly {
      height: 100%;
      width: 70px;
      color: var(--color-grey-light-4);
      font-weight: bold;
      font-size: 14px;
      margin: 0px 7px;
      cursor: pointer;
    }
    .select {
      border-bottom: 4px solid var(--color-main);
      color: var(--color-main);
    }
  }
  .daily-reports-date-picker-section{
    height: 37px;
    margin-left: 10px;
    width: 200px;
    border: 1px solid #0090FF;
    border-radius: 5px;
    display: flex;
    position: relative;
    top: 8px;
    right: 5px;
    .daily-reports-day-picked{
    width: 40%;
    background-color:#0090FF ;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    p{
        font-size: 12px;
    }
 }
 .daily-reports-date-picker{
width: 70%;
color: #0090FF;
     display: flex;
     justify-content: space-between;
     align-content: center;
     align-items: center;
     i{
         margin-left: 10px;
         font-size: 16px;
         position: relative;
         top: 10px;
     }
     p{
         margin-right: 10px;
     }
 }
}
.date-picker-card{
  input{
      visibility: hidden ;
      position: absolute;
      opacity: 1;
  }
}
.date-picker-spinner{
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  top: 6px;
}
}