.container-gallery {
  height: calc(100vh - 110px);
  position: relative;
  // border: 1px solid gray;
  .image-container {
    cursor: pointer;
    height: calc(100% - 100px);
    position: relative;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0) inset;
    overflow: hidden;
    padding: 8px;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    // margin-right: 20px;
    iframe {
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
        0 0 40px rgba(0, 0, 0, 0) inset;
      -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
        0 0 40px rgba(0, 0, 0, 0) inset;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0) inset;
      width: 100%;
      height: 100%;
      // cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      // cursor: pointer;
    }
  }
  .bottom-row {
    white-space: nowrap;
    overflow-x: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-flex;
    display: -webkit-box;
    margin-left: 0px;
    margin-right: 20px;
    .card {
      display: flex;
      width: 78px;
      height: 78px;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      padding: 1px;
      overflow: hidden;
      cursor: pointer;
      padding: 4px;
      img {
        max-width: 80px;
        max-height: 80px;
      }
    }
    .card.active {
      border: 1px solid #007bff;
      padding: 0px;
    }
  }
}

.document-viewer {
  height: 100%;
  iframe {
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 0, 0, 0) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0) inset;
    width: 100%;
    height: 100%;
  }
}
