.component-wrapper {
  .header {
    ul {
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      li {
        //margin-right: 2rem;
        padding: 1rem;
        font-size: var(--default-font-size);
        font-weight: bold;
        cursor: pointer;
        font-size: 15px;
      }
      .selected-li {
        border-style: solid;
        border-width: 0 0 0.4rem 0;
        border-color: var(--color-main);
      }
      .add-opertaion-btn {
        //width: 70%;
        background-color: var(--color-main);
        margin-left: auto;
        color: white;
        border-radius: 10px;
        height: 40px;
        text-align: justify;
        display: flex;

        align-items: center;
      }
    }
  }

  .grad-line {
    width: 100%;
    height: 0.4rem;
    background: linear-gradient(0deg, #ffffff 0%, rgba(206, 206, 206, 0.5) 100%)
      padding-box;
  }
}
.update-price-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  .submit-price-update {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    div {
      background-color: var(--color-main);
      color: white;
      font-size: 12px;
      border-radius: 10px;
      padding: 5px 25px;
      font-family: 'Segoe UI';
      cursor: pointer;
    }
  }
}
