/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-toggle/style.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  // font-weight: 300;
  src: url('./assets/fonts/segoeui.otf');
  // unicode-range: U+600-65F;
}

@font-face {
  font-family: 'GE SS Two';
  font-style: normal;
  // font-weight: 300;
  src: url('./assets/fonts/GE_SS_Two_Medium_0.otf');
  unicode-range: U+600-65F;
}

:root {
  //COLORS
  --color-main: #0090ff;
  --color-main-light: #f2f9ff;

  --color-primary: #55c57a;
  --color-primary-light: #7ed56f;
  --color-primary-dark: #1b8b40;
  --color-primary-dark1: #31b260;
  --color-primary-dark2: #1ACCBA;

  --color-secondary-light: #ffb900;
  --color-secondary-dark: #ff7730;

  --color-tertiary-light: #8ec5f8;
  --color-tertiary-dark: #5634fa;

  --color-danger: #ff1654;
  --color-danger-light-1: #ffecf1;
  --color-danger-light-2: #f31d73;

  --color-red-1: #cc1818;
  --color-red-2: #9E0A0A;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-light-5: #dddada;
  --color-gery-light-6: #e2e2e2;

  --color-grey-dark-1: #a7a7a7;
  --color-grey-dark-2: #999;
  --color-grey-dark-3: #777;
  --color-grey-dark-4: #333;
  --color-grey-dark-5: #e9e9e9;
  --color-grey-dark-6: #9a9a9a;
  --color-grey-dark-7: #7d7d7d;
  --color-grey-dark-8: #434343;

  --line: 1px solid var(--color-grey-light-2);

  --color-white: #fff;
  --color-black: #000;

  --color-time-slot-inactive: #f8cecc;
  --color-time-slot-active: #d5e8d4;

  //FONT
  --default-font-size: 1.5rem;
  --font-size-1: 1.2rem;
  --font-size-2: 1.3rem;
  --font-size-3: 1rem;
  --font-size-4: 0.9rem;
  --font-size-5: 1.1rem;
  --font-size-6: 1.4rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  @media (min-width: 85.4em) {
    & {
      font-size: 75%;
    }
  }
  //overflow: hidden;
}
body {
  box-sizing: border-box;
  // font-weight: 400;
  line-height: 1.6;
}

*:not(i) {
  font-family: 'GE SS Two'; //!important;
}

.scrollHide {
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-main);
}

.clock {
  div {
    color: #000 !important ;
    font-size: 2rem;
    font-weight: 700;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.custom-classname .react-toggle-track {
  background-color: var(--color-grey-light-4);
}
.custom-classname.react-toggle--checked .react-toggle-track {
  background-color: var(--color-main);
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-grey-light-4);
}

.react-toggle-track {
  width: 45px;
  height: 20px;
}

.react-toggle-thumb {
  border: none !important;
  width: 19px;
  height: 18px;
  &:active,
  :focus {
    outline: none;
  }
}

.react-toggle--checked .react-toggle-thumb {
  left: 25px;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: unset;
}

h6,
p {
  margin: 0;
  font-size: var(--font-size-1);
}

a {
  font-size: var(--font-size-4);
}

.back {
  color: var(--color-grey-dark-1);
  font-size: 1.3rem;
  cursor: pointer;
  img {
    height: 1.7rem;
    margin: 0px 4px;
    // transform: rotate(180deg);
  }
}

input,
textarea,
select {
  resize: none;
  outline: none;
  flex: 1;
  min-width: 0;
  border: 1px solid var(--color-grey-light-5);
  background-color: var(--color-white);
  color: var(--color-grey-dark-1);
  border-radius: 0.8rem;
  padding: 0.9rem;
  font-size: var(--font-size-1);
  font-family: inherit;
  &::placeholder {
    color: var(--color-grey-dark-1);
    font-size: var(--font-size-3) !important;
  }
  &:focus,
  &:active {
    color: var(--color-main);
    border: 1px solid var(--color-main);
    background-color: var(--color-white);
  }
}

select {
  background: var(--color-white);
  cursor: pointer;
  appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-container {
  button {
    width: 100%;
    padding: 0.7rem;
    font-size: var(--font-size-2);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    background: var(--color-main);
    outline: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
}
.link-button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--color-main);
  text-decoration: underline;
  &:disabled {
    opacity: 0.5;
  }
  &:focus,
  &:hover {
    outline: none;
  }
}

.segment {
  padding: 0 2rem;
  width: 80%;
  margin: auto;
  background-color: var(--color-white);
  text-align: center;
  box-sizing: border-box;
  display: flex;
  ul {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    height: 3.7rem;
    border: 1px solid var(--color-main);
    border-radius: 1rem;
    margin: 0;
    overflow: hidden;
    .base {
      border-color: var(--color-main);
      color: var(--color-main);
      background: var(--color-white);
    }
    .selected {
      background: var(--color-main);
      color: var(--color-white);
      border-radius: 0.8rem;
    }
    li {
      background: var(--color-white);
      color: var(--color-main);
      font-size: var(--font-size-2);
      height: 100%;
      // cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      img {
        height: 1.8rem;
        margin: 0 0.3rem;
      }
    }
  }
}

[dir='ltr'] {
  *:not(i) {
    font-family: 'Segoe UI'; //!important;
  }
  .mobile-icon {
    img {
      transform: rotate(90deg);
    }
  }
  .back {
    img {
      transform: rotate(180deg);
    }
  }
  .go-back {
    img {
      transform: rotate(0deg);
    }
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #4285f4;
  // border: none;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.react-datepicker {
  width: 20rem;
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 0.5rem;
    text-align-last: center;
  }
  .react-datepicker__day,
  .react-datepicker__day--020,
  .react-datepicker__day--selected,
  .react-datepicker__day--today,
  .react-datepicker__day--weekend {
    font-size: 1rem;
  }
  .react-datepicker__navigation:focus,
  .react-datepicker__navigation--next:focus,
  .react-datepicker__navigation:focus,
  .react-datepicker__navigation--previous:focus {
    outline: none;
  }
}

@media (min-width: 576px) {
  .p-sm-4 {
    padding: 12px !important;
  }
}

@media (max-width: 576px) {
  .segment {
    padding: 0;
    width: 95%;
  }
}

.ar-font {
  font-family: 'GE SS Two' !important;
}
